import React, {useEffect} from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet } from 'components';
import { SiteLink } from 'components';

import { getTaskStatusName } from './taskEnums';
import { Loading, ErrorPopup } from 'components';
//import FormPanel from 'form/components/FormPanel';

export function BacklogList(props) {

  const sprintId = props.sprintId || '';
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/task/backloglist");
    // eslint-disable-next-line
  }, [sprintId]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  return (<TaskListTable data={data} />);
}

export function TaskListTable(props) {
  const { navigate } = useRouter();
  const data = props.data;
  console.log(data)
  return (
    <div className="container-wide">
      <h1 className="">Backlog List</h1>
      <div className="actions">
        <button className="btn btn-primary" type="button" onClick={() => {navigate('/task/new?type=backlog')}}>Add Task</button>
      </div>
      <table className="dt">
        <thead>
          <tr>
            <th>Project/Client</th>
            <th>Task</th>
            <th>Status</th>
            <th>Who</th>
            <th>Working Hours <div style={{fontSize: '70%'}}>(Spent/Plan)</div></th>
            <th>Billing Hours <div style={{fontSize: '70%'}}>(Plan)</div></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(task => (
            <tr key={task.taskId} className={`taskStatus${task.taskStatus}`}>
              <td>{task.projectCode || task.clientCode}</td>
              <td><SiteLink to={`/task/view/${task.taskId}`}>{task.taskTitle} {task.urgent? (<span style={{color: 'red'}}>(Urgent!)</span>) : ''} </SiteLink></td>
              <td>{getTaskStatusName(task.taskStatus)}</td>
              <td>{task.staffCode}</td>
              <td><b>{task.workingHoursSpent || 0}</b> / {task.workingHoursPlanned}</td>
              <td>{task.billingHoursPlanned || 0}</td>
              <td><SiteLink to={`/task/view/${task.taskId}`}>view</SiteLink> | <SiteLink to={`/task/edit/${task.taskId}`}>edit</SiteLink></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}