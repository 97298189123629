import React, {useEffect} from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet } from 'components';
import { SiteLink } from 'components';
import utils from 'utils';

import { Loading, ErrorPopup } from 'components';
//import FormPanel from 'form/components/FormPanel';

import { getprojectStatusName } from './projectEnums';
// /import { nb } from 'date-fns/locale';

export function ProjectList(props) {

  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/project/projectlist");
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }
  
  const data = get.response;
  return (<ProjectListTable data={data} />);
}

const n = utils.text.formatNumber;

export function ProjectListTable(props) {
  const { navigate } = useRouter();
  const [projectStatus, setProjectStatus] = React.useState(localStorage.getItem('projectStatus') || '');

  const data = props.data;
  
  function changeProjectStatus(e) {
    localStorage.setItem('projectStatus', e.target.value);
    setProjectStatus(e.target.value);
    //console.log(e.target.value, typeof (e.target.value))
  }

  const statusList = [
    {value: 0, text: 'Working' },
    {value: 1, text: 'WaitingForClient' },
    {value: 2, text: 'NotStarted' },
    {value: 99, text: 'Completed' }
  ]

  const projects = (projectStatus || (projectStatus === 0)) ? data.filter(x => x.projectStatus.toString() === projectStatus) : data;

  const prePaidProjects = projects.filter(x => utils.site.isPrePurchased(x.projectType));
  const nonPrePaidProjects = projects.filter(x => !utils.site.isPrePurchased(x.projectType));

  return (
    <div className="container-wide">
      <h1 className="">Project List</h1>
      <div className="actions">
        <button className="btn btn-primary" type="button" onClick={() => {navigate('/project/new')}}>Add Project</button>
        <div className="filter">
          <select className="form-control" value={projectStatus} onChange={changeProjectStatus}>
            <option value="">All</option>
            {statusList.map(x => <option key={x.text} value={x.value}>{x.text}</option>)}
          </select>
        </div>
      </div>

      <PrePaidProjectList projects={prePaidProjects} />
      <hr/>
      <NormalProjectList projects={nonPrePaidProjects} />
    </div>
  );
}

function PrePaidProjectList( {projects}) {
  return <table className="dt">
  <thead className='project__head'>
    <tr>
      {/* <th>Project Code</th> */}
      <th>Project Name</th>
      <th>Balance</th>
      <th>Purchased</th>
      <th>Used (billing hours)</th>
      <th>Worked</th>
      <th>From</th>
      <th>To</th>
    </tr>
  </thead>
  <tbody>
    {projects.map(project => (
      <tr key={project.projectCode} className={`projectStatus${project.projectStatus}`}>
        <td><SiteLink to={`/project/edit/${project.projectCode}`}>{project.projectName} </SiteLink></td>
        <td style={{fontSize: '100%'}}>{displayPPHours(project.ppBalance)}</td>
        <td style={{fontSize: '80%'}}>{n(project.ppTotalPurchased)}</td>
        <td style={{fontSize: '80%'}}>{n(project.ppTotalUsed)}</td>
        <td style={{fontSize: '80%'}}>{n(project.ppTotalWorked)}</td>
        <td style={{fontSize: '80%'}}>{utils.date.formatDDMMYYYY(project.dateFrom)}</td>
        <td style={{fontSize: '80%'}}>{utils.date.formatDDMMYYYY(project.dateTo)}</td>
      </tr>
    ))}
  </tbody>
</table>

}

function NormalProjectList( {projects}) {
  return <table className="dt">
  <thead>
    <tr>
      {/* <th>Project Code</th> */}
      <th>Project Name</th>
      <th>Status</th>
      <th>PM Hours <div style={{fontSize: '70%'}}>(Left : Spent/Plan)</div></th>
      <th>Admin Hours <div style={{fontSize: '70%'}}>(Left : Spent/Plan)</div></th>
      <th>Design Hours <div style={{fontSize: '70%'}}>(Left : Spent/Plan)</div></th>
      <th>Tech Hours <div style={{fontSize: '70%'}}>(Left : Spent/Plan)</div></th>
      <th>From</th>
      <th>To</th>
    </tr>
  </thead>
  <tbody>
    {projects.map(project => (
      <tr key={project.projectCode} className={`projectStatus${project.projectStatus}`}>
        {/* <td>{project.projectCode}</td> */}
        <td><SiteLink to={`/project/edit/${project.projectCode}`}>{project.projectName} </SiteLink></td>
        <td style={{fontSize: '80%'}}>{getprojectStatusName(project.projectStatus)}</td>
        {utils.site.isPrePurchased(project.projectType)? 
        <React.Fragment>
<td style={{fontSize: '80%'}} colSpan="3">Pre-purchased project</td>
        </React.Fragment> :  <React.Fragment>
        <td style={{fontSize: '80%'}}>{displayHours(project.projectCode, project.pmHoursLeft, project.pmHoursSpent, project.pmHoursPlanned)}</td>
        <td style={{fontSize: '80%'}}>{displayHours(project.projectCode, project.adminHoursLeft, project.adminHoursSpent, project.adminHoursPlanned)}</td>
        <td style={{fontSize: '80%'}}>{displayHours(project.projectCode, project.designHoursLeft, project.designHoursSpent, project.designHoursPlanned)}</td>
        <td style={{fontSize: '80%'}}>{displayHours(project.projectCode, project.techHoursLeft, project.techHoursSpent, project.techHoursPlanned)}</td>
</React.Fragment> 
        }
        
        {/* <td><span className={`projectBudgetStatus${getStatus(project.pmHoursLeft)}`}>{n(project.pmHoursLeft)}</span> : {n(project.pmHoursSpent || 0)} / {n(project.pmHoursPlanned)}</td>
        <td><span className={`projectBudgetStatus${getStatus(project.designHoursLeft)}`}>{n(project.designHoursLeft)}</span> : {n(project.designHoursSpent || 0)} / {n(project.designHoursPlanned)}</td>
        <td><span className={`projectBudgetStatus${getStatus(project.techHoursLeft)}`}>{n(project.techHoursLeft)}</span> : {n(project.techHoursSpent || 0)} / {n(project.techHoursPlanned)}</td> */}
        <td style={{fontSize: '80%'}}>{utils.date.formatDDMMYYYY(project.dateFrom)}</td>
        <td style={{fontSize: '80%'}}>{utils.date.formatDDMMYYYY(project.dateTo)}</td>
      </tr>
    ))}
  </tbody>
</table>

}

function displayHours(productCode, leftHours, spentHours, plannedHours){
  const disp = (productCode) => productCode && productCode.toLowerCase().indexOf('clutter') < 0 && productCode.toLowerCase().indexOf('r&d') < 0 && productCode.toLowerCase() !== 'sales'
  const getStatus = (hours) => {
    if (hours < 0) return 'Minus';
    else if (hours > 0) return 'OK';
    else return '';
  }
  return !disp(productCode) ?
  <div style={{paddingLeft:'4.3rem'}}>{n(spentHours || 0)}</div> 
  :
  <><span className={`projectBudgetStatus${getStatus(leftHours)}`}>{n(leftHours)}</span> : {n(spentHours || 0)} / {n(plannedHours)}</>
}

function displayPPHours(hours){
  const getStatus = (hours) => {
    if (hours < 0) return 'Minus';
    else if (hours > 0) return 'OK';
    else return '';
  }
  return <div><span className={`projectBudgetStatus${getStatus(hours)}`}>{n(hours || 0)}</span></div>
}