import React from 'react';
import { Route } from 'react-router-dom';
import { useRouter } from 'components';
import { useAuth } from 'auth';
// import { AppHeader } from 'layout/appHeader';
// import { AppSideBar } from 'layout/appSideBar';

import { TopHeader } from './topHeader/topHeader';

const DashboardLayout = ({ children, ...rest }) => {
  const { Redirect } = useRouter();
  const auth = useAuth();
  //console.log(auth.session)
  // React.useEffect(() => {
  //   console.log(auth.session)
  //   if (!(auth.session && !auth.session.expired())) {
  //     // no valid session
  //     navigate("/admin/login")
  //   }
  //   // eslint-disable-next-line 
  // }, [])

  React.useEffect(() => {
    const checkAuth = async() => {
      if (!(await auth.loggedIn())) {
        return <Redirect to="/admin/login" />
      }
    }
    checkAuth();
  }, [auth]);

  
  return (
    
    <div className={`body-content subpage`}>
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
        {children}
        </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
    // <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer">
    //   <AppHeader />

    //   <TopHeader></TopHeader>
    //   <div className="appMain">
    //     <AppSideBar />
    //     <div className="appMain__outer">
    //       <div className="appMain__inner">
    //         {children}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

const DashboardLayoutRoute = ({ component: RenderComponent, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <DashboardLayout>
        <RenderComponent {...matchProps} />
      </DashboardLayout>
    )} />
  )
};

export default DashboardLayoutRoute;