import { fb, validators } from 'form';

export function getClientEditModel(data) {

  
  const statusList = [
    {value: '', name: 'Select...' },
    {value: 0, name: 'Active' },
    {value: 99, name: 'Inactive' }
  ]
    // const model = [
    //     //fb.control('clientCode', 'Client Code', "text", data.clientCode || "", [Required()]),
    //     fb.control('clientName', 'Client Name', "text", data.clientName, [validators.Required()]),
    // ];
    const model = fb.group({
        clientName: [data.clientName || '', [validators.Required()], { label: 'Client Name', type: 'text' }],
        clientStatus: [data.clientStatus || '0', [validators.Required()], { label: 'Status', type: 'select', options: statusList }]
      });
    return model;
};
