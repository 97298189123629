import React, { useState, useEffect } from "react";
import { useRouter } from "../../components";
//import { navigate, usePath, useLocationChange } from 'raviger';
import { SiteLink } from "../../components";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "../../components";
import utils from "../../utils";

import { LoginStatus } from "auth";
export function TopHeader(props) {
  const { pathname } = useRouter();
  //const path = usePath();
  const [, setCurrentLocation] = useState(pathname);

  //const [scrolled, setScrolled] = useState(false)
  // const history = useHistory();
  // console.log(history.length, history)

  const history = useHistory(pathname);

  const routeChanged = (path, history) => {
    //console.log(path, history);
    //console.log(window.location.hash)
    if (typeof window !== `undefined`) {
      const hash = window.location.hash;
      const search = window.location.search;
      history.push(path + hash + search);

      if (hash) {
        scrollTo();
      }
    }
  };

  useEffect(() => {
    scrollTo();
  }, []);

  //const pageChanged = useCallback(path => routeChanged(path, history), [history])
  // useLocationChange(path => {
  //   routeChanged(path, history);
  //   setCurrentLocation(path);
  // })

  useEffect(() => {
    routeChanged(pathname, history);
    setCurrentLocation(pathname);
    // eslint-disable-next-line
  }, [pathname]);

  function scrollTo() {
    const hash = window.location.hash;
    if (hash) {
      //console.log(hash)
      setTimeout(() => {
        const anchor = utils.ui.findElement(hash);
        if (anchor) utils.ui.scrollTo(anchor, 500);
      }, 500);
    }
  }

  function goBack(e) {
    //e.preventDefault();
    history.getPrevious();
    //console.log(previousLink)
  }

  // useEffect(() => {
  //   console.log('use history')
  // }, []);

  return (
    <header className="top" id="top">
      <div className="top-bar">
        {history.history.length > 1 && (
          <SiteLink
            className="topNav__back back"
            title="Go Back"
            to={history.getPreviousPath()}
            onClick={goBack}
          >
            <FaAngleLeft />
          </SiteLink>
        )}
        {/* {history.history.length > 1 && <a className="topNav__back" title="Go Back" href="/" onClick={(e) => goBack(e)}>
          <FaAngleLeft /> BACK
        </a>} */}
        <div className="container" style={{ marginTop: "1rem" }}>
          <SiteLink to="/">Dashboard</SiteLink>
          <SiteLink to="/planner">Planner</SiteLink>
          <SiteLink to="/tasks">Tasks</SiteLink>
          <SiteLink to="/projects">Projects</SiteLink>
          <SiteLink to="/clients">Clients</SiteLink>
          <SiteLink to="/websites">Websites</SiteLink>
          <SiteLink to="/backlogs">Backlogs</SiteLink>

          <SiteLink to="/settings">Settings</SiteLink>
        </div>
        <LoginStatus />
      </div>

      {/* <button id="mobile-menu-icon" type="button" className={`hamburger hamburger--collapse ${menuOpened ? 'is-active' : ''}`} onClick={toggleMenu}>
        <span className="hamburger-box">
          <span className="hamburger-inner">
          </span>
        </span>
      </button>

      <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar> */}
    </header>
  );
}
