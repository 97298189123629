import React, { useEffect } from "react";
import env from "env";
import { useRouter } from "components";
import { useGet, usePost } from "components";

import { useForm } from "form";
import { Button, ErrorMessage } from "components";
import { Loading, ErrorPopup } from "components";
//import FormPanel from 'form/components/FormPanel';
import { getTaskEditModel } from "./taskEditModel";
import { SiteLink } from "components";

export function TaskEdit(props) {
  const { query } = useRouter();
  const taskId = query.id || "";
  const taskType = query.type || "weekly";
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/task/gettask/" + taskId + "?type=" + taskType);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  const data = get.response;
  const model = getTaskEditModel(data);
  return <TaskEditForm model={model} taskId={taskId} initModel={data} />;
}

export function TaskEditForm(props) {
  const model = props.model;
  const taskId = props.taskId;
  const initModel = props.initModel;
  const { navigate } = useRouter();

  console.log(model);
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(
      e,
      () => {
        const value = { ...initModel, ...form.getValue() };
        post.send(env.apiBase + "/api/task/savetask", value);
      },
      (errors) => {
        console.log(errors);
      }
    );
  }

  function removeTask(e) {
    if (!window.confirm("Delete this??")) return;
    post.send(env.apiBase + "/api/task/removetask", { taskId: taskId });
  }

  if (post.done()) {
    navigate(`/tasks`);
  }

  const data = form.getValue();
  const render = (name) => form.renderControl(name);

  return (
    <div className="container">
      <h1 className="">{taskId ? "Edit" : "New"} Task</h1>
      <form>
        {/* {controls.map(input => renderControl(input, handleChange))} */}
        <div className="row">
          <div className="col-md-6">
            <div className="addNew">
              <SiteLink to="/project/new">Add New Project (move away)</SiteLink>
            </div>
            {render("projectCode")}{" "}
          </div>
          <div className="col-md-6">
            <div className="addNew">
              <SiteLink to="/client/new">Add New Client (move away)</SiteLink>
            </div>
            {render("clientCode")}{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">{render("taskTitle")}</div>
          <div className="col-md-6">{render("sprintId")}</div>
          <div className="col-md-6">{render("urgent")}</div>
          <div className="col-md-6">{render("dateTo")}</div>
        </div>
        <div className="row">
          <div className="col-md-6">{render("taskStatus")}</div>
          <div className="col-md-6">{render("staffCode")}</div>
        </div>
        {!data.projectCode && data.clientCode && (
          <div className="row">
            {/* <div className="col-md-6">{render('workingHoursPlanned')}</div> */}
            <div className="col-md-6">{render("billingHoursPlanned")}</div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">{render("taskDetail")}</div>
        </div>

        <div className="actions">
          <Button onClick={submitCallback} status={post.status}>
            Save
          </Button>
          {taskId && (
            <Button
              onClick={() => {
                removeTask();
              }}
              status={post.status}
              btnStyle="outline-primary"
            >
              Delete
            </Button>
          )}
          <ErrorMessage
            errors={form.errors}
            summaryMessage="Please review the errors."
          />
          <ErrorMessage errors={post.errors} />
        </div>
      </form>

      {/* <FormPanel title="New Task" btnName="Register" submitCallback={submitCallback} model={model} /> */}
    </div>
  );
}
