import React from "react";
import env from "env";
//import { useRouter } from 'components';
import { usePost } from "components";

import { useForm } from "form";
import { Button, ErrorMessage } from "components";
//import FormPanel from 'form/components/FormPanel';
import { getNewLogModel } from "./newLogModel";

export function NewLog({ newLog, isPrePurchased, editing }) {
  const taskId = newLog.taskId;
  const workLogId = newLog.workLogId;
  const projectCode = newLog.projectCode;

  //console.log(newLog, isPrePurchased, editing)
  // use the log's project type first.
  const isPrePurchasedInternal =
    (editing && newLog.isPrePurchased) || isPrePurchased;
  //console.log(!!isPrePurchasedInternal)

  const model = getNewLogModel(newLog, isPrePurchasedInternal);
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(
      e,
      () => {
        const value = {
          ...form.getValue(),
          workLogId: workLogId,
          taskId: taskId,
          projectCode: projectCode,
        };
        console.log(value);
        let spentHours = converToHour(value.spentHours);

        //console.log(value.spentHours, spentHours)
        if (!spentHours) {
          alert("Please check the Spent Hour.");
          return;
        }
        value.spentHours = spentHours;

        if (isPrePurchasedInternal) {
          let billingHours = converToHour(value.billingHours);
          value.billingHours = billingHours;
        }

        localStorage.setItem("staffCodeLog", value.staffCode);
        post.send(env.apiBase + "/api/workLog/addLog", value);
      },
      (errors) => {
        console.log(errors);
      }
    );
  }

  function converToHour(val) {
    console.log(typeof val);
    if (typeof val === "number") return val;
    if (val.indexOf("m") >= 0) {
      const parsed = parseFloat(val.replace("m", ""));
      if (parsed) return parsed / 60;
      else return null;
    } else if (val.indexOf("min") >= 0) {
      const parsed = parseFloat(val.replace("min", ""));
      if (parsed) return parsed / 60;
      else return null;
    }
    return parseFloat(val);
  }

  if (post.done()) {
    //navigate(`/task/view/${taskId}`)
    window.location.reload();
  }

  const render = (name) => form.renderControl(name);

  return (
    <form className='newLog'>
      {/* {controls.map(input => renderControl(input, handleChange))} */}
      <div className='row'>
        <div className='col-md-4'>{render("staffCode")}</div>
        <div className='col-md-4'>{render("workLogType")}</div>
        <div className='col-md-4'>{render("timeStart")}</div>
      </div>
      <div className='row'>
        <div className='col-md-6'>{render("spentHours")}</div>
        {isPrePurchasedInternal && (
          <div className='col-md-6'>{render("billingHours")}</div>
        )}
      </div>
      <div className='row'>
        <div className='col-md-12'>{render("workLogDetails")}</div>
      </div>

      <div className='actions'>
        <Button onClick={submitCallback} status={post.status}>
          {editing ? "Save" : "Add Log"}
        </Button>
        <ErrorMessage
          errors={form.errors}
          summaryMessage='Please review the errors.'
        />
        <ErrorMessage errors={post.errors} />
      </div>
    </form>
  );
}
