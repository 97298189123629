import { fb, validators } from "form";

export function getNewLogModel(data, isPrePurchased) {
  //console.log(data);
  let staffList = data.staffList.map((p) => {
    return { name: p.name, value: p.staffCode };
  });
  staffList = [{ name: "Select...", value: "" }, ...staffList];

  const workType = [
    { value: "", name: "Select..." },
    { value: 0, name: "PM" },
    { value: 3, name: "Admin" },
    { value: 1, name: "Design/Content" },
    { value: 2, name: "Tech" },
  ];

  const staffCode =
    data.staffCode || localStorage.getItem("staffCodeLog") || "";
  // eslint-disable-next-line
  let workLogType = "";
  if (staffCode === "Adrian") workLogType = 1;
  else if (staffCode === "Emily") workLogType = 1;
  else if (staffCode === "JC") workLogType = 2;
  else if (staffCode === "Sangmin") workLogType = 2;
  else if (staffCode === "Jack") workLogType = 0;
  else if (staffCode === "Team") workLogType = 0;

  const model = fb.group({
    timeStart: [
      data.timeStart || "",
      [validators.Required()],
      { label: "Date", type: "date" },
    ],
    spentHours: [
      data.spentHours || "",
      [validators.Required()],
      { label: `Hours - How long? (hrs, or use 'm')`, type: "text" },
    ],
    billingHours: [
      data.billingHours === 0 ? 0 : data.billingHours || "",
      isPrePurchased ? [validators.Required()] : [],
      { label: `Billable hours (hrs, or use 'm')`, type: "text" },
    ],
    staffCode: [
      staffCode || "",
      [validators.Required()],
      { label: "Team Member", type: "select", options: staffList },
    ],
    workLogType: [
      data.workLogType === 0 ? 0 : data.workLogType || "",
      [validators.Required()],
      { label: "Work Type", type: "select", options: workType },
    ],
    workLogDetails: [
      data.workLogDetails || "",
      [validators.Required()],
      { label: "Details", type: "textarea" },
    ],
  });

  return model;
}
