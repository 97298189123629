import React from 'react';
import ValidationAlert from '../components/ValidationAlert';
//import utils from '../../utils';
import { getLabel } from '../formRenderHelper';
//import ReactQuill from 'react-quill';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// // //import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';

//import debounce from "lodash/debounce";

//------------- ckeditor build source directly
import CKEditor from "@ckeditor/ckeditor5-react";
// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import List from "@ckeditor/ckeditor5-list/src/list";
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Font from '@ckeditor/ckeditor5-font/src/font';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';

//------------- ckeditor build source directly

function HtmlInput({ control, handleChange, formOption }) {
  const { name, label, value, errors, style, helpText, attrs } = control;
  //const fieldId = utils.random.shortId();
  const fieldLabel = getLabel(control, formOption);
  const [viewMode, setViewMode] = React.useState('html');
  const [htmlSource, setHtmlSource] = React.useState(value);
  //const { template = 'bootstrap', usePlaceholder = false } = formOption;
  const { usePlaceholder = false } = formOption;
  //const placeHolder = usePlaceholder ? fieldLabel : null;
  const valueStr = value === 0 ? 0 : (value || "");

  function handleChangeInner(newVal) {
    //console.log(newVal)
    //mimic event
    handleChange({
      persist: () => { },
      target: { name: name, value: newVal, checked: false }
    }, control)
  }

  const onTextareaChanged = (e) => {
    setHtmlSource(e.target.value)
  }

  function applyToHtml() {
    handleChangeInner(htmlSource)
    setViewMode('html')
  }

  function changeToEditor() {
    setViewMode('html')
  }

  function changeToSource() {
    setHtmlSource(valueStr)
    setViewMode('source')
  }

  return (
    <div className={`htmlEditor ${style && style.className}`}>
      {!usePlaceholder && fieldLabel && <label htmlFor={name} className="label">{fieldLabel}</label>}
      {/* {<ReactQuill value={valueStr}
        onChange={handleChangeInner} />} */}

      <div className={`htmlEditor__panel ${viewMode==='html'? 'active' : ''}`}>
      <CKEditor
        editor={ClassicEditor}
        data={valueStr}
        config={{ 
          plugins: [Essentials, Bold, Italic, Paragraph, List, RemoveFormat, Heading, Link, Font, ImagePlugin, ImageInsert],
          toolbar: ['removeFormat', '|',  'heading', 'fontSize', '|',  "bold", "italic", 'link', 'bulletedList', 'numberedList', '|', 'imageinsert'],
          fontSize: {
            options: [
                'tiny',
                'default',
                'big'
            ]
        },
          heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading5' },
                { model: 'fineprint', view: 'h5', title: 'Fine Print', class: 'ck-heading_heading5' }
            ]
        }
          //plugins: [ RemoveFormat ],
          //'removeFormat', 
          //toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'] }}
        //config={{ toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', 'undo', 'redo'] }}
        // onInit={editor => {
        //   console.log(valueStr, control.id)
        //   editor.controlId = control.id;
        //   console.log( Array.from( editor.ui.componentFactory.names() ) )
        //   // You can store the "editor" and use when it is needed.
        //   console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChangeInner(data);
          //console.log({ event, editor, data });
        }}
      />
      </div>
      <div className={`htmlEditor__panel ${viewMode==='source'? 'active' : ''}`}>
      <textarea className="form-control" value={htmlSource} onChange={onTextareaChanged} {...attrs}></textarea>
      <button className={`btn btn-primary`} style={{margin:'5px 0', width:'100%', fontSize: '0.8rem'}} onClick={applyToHtml}>Apply to Html</button>
      </div>

      <div className="htmlEditor__view">
        <button type="button" className={`btn ${viewMode==='html'? 'disabled' : ''}`} onClick={changeToEditor}>editor view</button>
        <button type="button" className={`btn ${viewMode==='source'? 'disabled' : ''}`} onClick={changeToSource}>source view</button>
      </div>

      {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
      <ValidationAlert errors={errors} />
    </div>
  )
}

export default HtmlInput;