import React, { useEffect } from "react";
import env from "env";
import { useRouter } from "components";
import { useGet } from "components";
import { usePost } from "components";
import { SiteLink } from "components";

import { Loading, ErrorPopup } from "components";
import { getprojectStatusName } from "../project/projectEnums";
//import FormPanel from 'form/components/FormPanel';
import utils from "utils";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaGripLines, FaPlusSquare, FaMinusSquare } from "react-icons/fa";

// const n2 = utils.text.formatNumber;
const n = (value) => {
  if (value === 0) return "0";
  if (!value) return "";
  return Math.round(value * 10) / 10;
};
const getDate = (d) => utils.date.formatDate(d, "d MMM yyyy") || "-";
const getDueDateClass = (d) => {
  const date = utils.date.toDate(d);
  const now = new Date();
  return date ? (date > now ? "green" : "red") : "";
};

export function Planner(props) {
  const { query } = useRouter();
  const sprintId = query.id || "";

  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/planner/getplanner");
    // eslint-disable-next-line
  }, [sprintId]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }
  const data = get.response;
  console.log("data ----->", data);

  return <ProjectListTable data={data} sprintId={sprintId} />;
}

const workType = [
  { value: 0, name: "PM" },
  { value: 3, name: "Admin" },
  { value: 1, name: "Design/Content" },
  { value: 2, name: "Tech" },
  { value: 99, name: "Purchased Hours" },
];

function getWorkLogType(typeId) {
  var type = workType.find((x) => x.value === typeId);
  return type ? type.name : "";
}

function WorkLogItems({ workLog }) {
  return (
    <div className='workLog'>
      <div className='date'>{utils.date.formatDDMMYYYY(workLog.timeStart)}</div>
      <div className='staff'>
        {workLog.workLogType === 99 ? (
          <span> {getWorkLogType(workLog.workLogType)}</span>
        ) : (
          <span>
            {workLog.staffCode} / {getWorkLogType(workLog.workLogType)}
          </span>
        )}
      </div>
      {/* <div className="workType">{getWorkLogType(workLog.workLogType)}</div> */}
      <div className='hrs'>
        {n(workLog.spentHours)} hrs
        {workLog.workLogType !== 99 && (
          <div className='billingHrs'>
            {n(workLog.billingHoursUsed || 0)} hrs
          </div>
        )}
      </div>
      <div className='desc'>{workLog.workLogDetails}</div>
    </div>
  );
}

export function ProjectListTable(props) {
  // const { navigate } = useRouter();
  const post = usePost();

  const [filteredProjects, setFilteredProjects] = React.useState(
    props.data.projects
  );

  const [displayingProjects, setDisplayingProjects] = React.useState([]);

  const displayingLogs = (projectCode, type) => {
    const found = displayingProjects.find(
      (x) => x.projectCode === projectCode && x.type === type
    );
    return !!found;
  };

  const showLog = (projectCode, type) => {
    setDisplayingProjects([...displayingProjects, ...[{ projectCode, type }]]);
  };

  const hideLog = (projectCode, type) => {
    setDisplayingProjects(
      displayingProjects.filter(
        (x) => !(x.projectCode === projectCode && x.type === type)
      )
    );
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : null,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : null,
  });

  function getPercent(spent, planned) {
    const s = spent || 0;
    const p = planned || 0;
    if (p) {
      if (s) {
        if (s <= p) return (s / p) * 100 + "%";
        else {
          return s / p > 2 ? "100%" : (s / p - 1) * 100 + "%";
        }
      }
    } else {
      if (s) return "100%";
    }
    return "0.2%";
  }

  function getProgressStatus(spent, planned) {
    const s = spent || 0;
    const p = planned || 0;
    return (s && p && s > p) || (s && !p) ? "red" : "";
  }

  function getEstimatedWorkCompleted(project) {
    return project.estimatedWorkCompleted
      ? project.estimatedWorkCompleted + "%"
      : "-";
  }

  function getPrepaidHoursPurchased(project) {
    return project.prePaidSummary.totalPurchased
      ? project.prePaidSummary.totalPurchased + "h"
      : "-";
  }

  function getPrepaidStatus(project) {
    return project.prePaidSummary.balance < 0 ? "red" : "";
  }

  function getQuotedTotal(project) {
    return project.totalBillingAmount
      ? "$" + n(project.totalBillingAmount / 1000) + "k"
      : "-";
  }

  function getPrepaidRemaining(project) {
    return project.prePaidSummary.balance
      ? project.prePaidSummary.balance.toFixed(2) + "h"
      : "-";
  }

  function getAmountBilledPercent(project) {
    return project.amountBilledPercent
      ? project.amountBilledPercent + "%"
      : "-";
  }

  function getBillableAmount(project) {
    return project.totalBillingAmount
      ? "$" +
          n(
            (project.totalBillingAmount / 1000) *
              (1 -
                (project.amountBilledPercent
                  ? project.amountBilledPercent / 100
                  : 1))
          ) +
          "k"
      : "-";
  }

  function getBilledPercent(project) {
    return (project.amountBilledPercent || 0) + "%";
  }

  function getLeftPercent(project) {
    return 100 - (project.amountBilledPercent || 0) + "%";
  }

  function onDragEnd(result) {
    //console.log(result)

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sorted = utils.array.moveItem(
      Array.from(filteredProjects || []),
      result.source.index,
      result.destination.index
    );
    setFilteredProjects(sorted);
    const ids = sorted.map((x) => x.projectCode);
    const model = { ids: ids };
    post.send(env.apiBase + "/api/project/saveProjectOrders", model);
  }

  return (
    <div className='container-wide planner'>
      <h1 className=''>Planner</h1>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className={`dt`}
            >
              {filteredProjects.map((project, index) => (
                <Draggable
                  key={project.projectCode}
                  draggableId={project.projectCode}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={`plannerProject plannerProject--${project.projectStatus}`}
                    >
                      <div className='plannerProject__handle'>
                        {
                          <div className='handle' {...provided.dragHandleProps}>
                            <FaGripLines title='Change order' />
                          </div>
                        }
                      </div>
                      <div className='plannerProject__details'>
                        <SiteLink
                          className='title'
                          to={`/project/edit/${project.projectCode}`}
                        >
                          {project.projectName}{" "}
                        </SiteLink>

                        <div className='status'>
                          STATUS:
                          <span>
                            {getprojectStatusName(project.projectStatus)}
                          </span>
                        </div>

                        <div
                          className={`dueDate dueDate--${getDueDateClass(
                            project.dateTo
                          )}`}
                        >
                          DUE:
                          <span>{getDate(project.dateTo)}</span>
                        </div>
                      </div>

                      {/* Pre-purchased */}
                      {project.projectType === "Pre-purchased" ? (
                        <>
                          <div className='plannerProject__hours'>
                            <div className='part'>
                              <div className='title'>PROD-MAN</div>
                              <div
                                className={`barWrapper barWrapper--${getProgressStatus(
                                  project.pmHoursSpent +
                                    project.adminHoursSpent,
                                  project.pmHoursPlanned +
                                    project.adminHoursPlanned
                                )}`}
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: getPercent(
                                      project.pmHoursSpent +
                                        project.adminHoursSpent,
                                      project.pmHoursPlanned +
                                        project.adminHoursPlanned
                                    ),
                                  }}
                                >
                                  <div className='figure'>
                                    {n(
                                      project.pmHoursSpent +
                                        project.adminHoursSpent
                                    )}
                                    /
                                    {n(
                                      project.pmHoursPlanned +
                                        project.adminHoursPlanned
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='logs'>
                                {displayingLogs(project.projectCode, "pm") ? (
                                  <FaMinusSquare
                                    onClick={() =>
                                      hideLog(project.projectCode, "pm")
                                    }
                                  />
                                ) : (
                                  <FaPlusSquare
                                    onClick={() =>
                                      showLog(project.projectCode, "pm")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {displayingLogs(project.projectCode, "pm") && (
                              <div className='partLogs'>
                                {project.workLogs
                                  .filter(
                                    (x) =>
                                      x.workLogType === 0 || x.workLogType === 3
                                  )
                                  .map((x) => (
                                    <WorkLogItems
                                      key={x.workLogId}
                                      workLog={x}
                                    />
                                  ))}
                              </div>
                            )}

                            <div className='part'>
                              <div className='title'>CREATIVE</div>
                              <div
                                className={`barWrapper barWrapper--${getProgressStatus(
                                  project.designHoursSpent,
                                  project.designHoursPlanned
                                )}`}
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: getPercent(
                                      project.designHoursSpent,
                                      project.designHoursPlanned
                                    ),
                                  }}
                                >
                                  <div className='figure'>
                                    {n(project.designHoursSpent)}/
                                    {n(project.designHoursPlanned)}
                                  </div>
                                </div>
                              </div>
                              <div className='logs'>
                                {displayingLogs(
                                  project.projectCode,
                                  "design"
                                ) ? (
                                  <FaMinusSquare
                                    onClick={() =>
                                      hideLog(project.projectCode, "design")
                                    }
                                  />
                                ) : (
                                  <FaPlusSquare
                                    onClick={() =>
                                      showLog(project.projectCode, "design")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {displayingLogs(project.projectCode, "design") && (
                              <div className='partLogs'>
                                {project.workLogs
                                  .filter((x) => x.workLogType === 1)
                                  .map((x) => (
                                    <WorkLogItems
                                      key={x.workLogId}
                                      workLog={x}
                                    />
                                  ))}
                              </div>
                            )}
                            <div className='part'>
                              <div className='title'>TECHNICAL</div>
                              <div
                                className={`barWrapper barWrapper--${getProgressStatus(
                                  project.techHoursSpent,
                                  project.techHoursPlanned
                                )}`}
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: getPercent(
                                      project.techHoursSpent,
                                      project.techHoursPlanned
                                    ),
                                  }}
                                >
                                  <div className='figure'>
                                    {n(project.techHoursSpent)}/
                                    {n(project.techHoursPlanned)}
                                  </div>
                                </div>
                              </div>
                              <div className='logs'>
                                {displayingLogs(project.projectCode, "tech") ? (
                                  <FaMinusSquare
                                    onClick={() =>
                                      hideLog(project.projectCode, "tech")
                                    }
                                  />
                                ) : (
                                  <FaPlusSquare
                                    onClick={() =>
                                      showLog(project.projectCode, "tech")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {displayingLogs(project.projectCode, "tech") && (
                              <div className='partLogs'>
                                {project.workLogs
                                  .filter((x) => x.workLogType === 2)
                                  .map((x) => (
                                    <WorkLogItems
                                      key={x.workLogId}
                                      workLog={x}
                                    />
                                  ))}
                              </div>
                            )}
                          </div>
                          <div className='plannerProject__completed'>
                            <div className='hdr'>Prepaid hours purchased</div>
                            <div className='value'>
                              {getPrepaidHoursPurchased(project)}
                            </div>
                          </div>
                          <div className='plannerProject__total'>
                            <div className='hdr'>Prepaid hours remaining</div>
                            <div
                              className={`value prepaid-remaining--${getPrepaidStatus(
                                project
                              )}`}
                            >
                              {getPrepaidRemaining(project)}
                            </div>
                          </div>
                          <div className='plannerProject__billed'>
                            <div className='hdr'>Project Amount Billed</div>
                            <div className='valuePrepaid'>
                              <span>Prepaid</span>
                            </div>
                          </div>
                          <div className='plannerProject__billLeft'>
                            <div className='hdr'>Billable Amount Available</div>
                            <div className='value'>
                              {getBillableAmount(project)}
                            </div>
                          </div>
                          <div className='plannerProject__billBar'>
                            <div
                              className='billed'
                              style={{ flexBasis: getBilledPercent(project) }}
                            ></div>
                            <div
                              className='left'
                              style={{ flexBasis: getLeftPercent(project) }}
                            ></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Normal project*/}
                          <div className='plannerProject__hours'>
                            <div className='part'>
                              <div className='title'>PROD-MAN</div>
                              <div
                                className={`barWrapper barWrapper--${getProgressStatus(
                                  project.pmHoursSpent +
                                    project.adminHoursSpent,
                                  project.pmHoursPlanned +
                                    project.adminHoursPlanned
                                )}`}
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: getPercent(
                                      project.pmHoursSpent +
                                        project.adminHoursSpent,
                                      project.pmHoursPlanned +
                                        project.adminHoursPlanned
                                    ),
                                  }}
                                >
                                  <div className='figure'>
                                    {n(
                                      project.pmHoursSpent +
                                        project.adminHoursSpent
                                    )}
                                    /
                                    {n(
                                      project.pmHoursPlanned +
                                        project.adminHoursPlanned
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='logs'>
                                {displayingLogs(project.projectCode, "pm") ? (
                                  <FaMinusSquare
                                    onClick={() =>
                                      hideLog(project.projectCode, "pm")
                                    }
                                  />
                                ) : (
                                  <FaPlusSquare
                                    onClick={() =>
                                      showLog(project.projectCode, "pm")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {displayingLogs(project.projectCode, "pm") && (
                              <div className='partLogs'>
                                {project.workLogs
                                  .filter(
                                    (x) =>
                                      x.workLogType === 0 || x.workLogType === 3
                                  )
                                  .map((x) => (
                                    <WorkLogItems
                                      key={x.workLogId}
                                      workLog={x}
                                    />
                                  ))}
                              </div>
                            )}

                            <div className='part'>
                              <div className='title'>CREATIVE</div>
                              <div
                                className={`barWrapper barWrapper--${getProgressStatus(
                                  project.designHoursSpent,
                                  project.designHoursPlanned
                                )}`}
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: getPercent(
                                      project.designHoursSpent,
                                      project.designHoursPlanned
                                    ),
                                  }}
                                >
                                  <div className='figure'>
                                    {n(project.designHoursSpent)}/
                                    {n(project.designHoursPlanned)}
                                  </div>
                                </div>
                              </div>
                              <div className='logs'>
                                {displayingLogs(
                                  project.projectCode,
                                  "design"
                                ) ? (
                                  <FaMinusSquare
                                    onClick={() =>
                                      hideLog(project.projectCode, "design")
                                    }
                                  />
                                ) : (
                                  <FaPlusSquare
                                    onClick={() =>
                                      showLog(project.projectCode, "design")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {displayingLogs(project.projectCode, "design") && (
                              <div className='partLogs'>
                                {project.workLogs
                                  .filter((x) => x.workLogType === 1)
                                  .map((x) => (
                                    <WorkLogItems
                                      key={x.workLogId}
                                      workLog={x}
                                    />
                                  ))}
                              </div>
                            )}
                            <div className='part'>
                              <div className='title'>TECHNICAL</div>
                              <div
                                className={`barWrapper barWrapper--${getProgressStatus(
                                  project.techHoursSpent,
                                  project.techHoursPlanned
                                )}`}
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: getPercent(
                                      project.techHoursSpent,
                                      project.techHoursPlanned
                                    ),
                                  }}
                                >
                                  <div className='figure'>
                                    {n(project.techHoursSpent)}/
                                    {n(project.techHoursPlanned)}
                                  </div>
                                </div>
                              </div>
                              <div className='logs'>
                                {displayingLogs(project.projectCode, "tech") ? (
                                  <FaMinusSquare
                                    onClick={() =>
                                      hideLog(project.projectCode, "tech")
                                    }
                                  />
                                ) : (
                                  <FaPlusSquare
                                    onClick={() =>
                                      showLog(project.projectCode, "tech")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {displayingLogs(project.projectCode, "tech") && (
                              <div className='partLogs'>
                                {project.workLogs
                                  .filter((x) => x.workLogType === 2)
                                  .map((x) => (
                                    <WorkLogItems
                                      key={x.workLogId}
                                      workLog={x}
                                    />
                                  ))}
                              </div>
                            )}
                          </div>
                          <div className='plannerProject__completed'>
                            <div className='hdr'>Estimated Work Completed</div>
                            <div className='value'>
                              {getEstimatedWorkCompleted(project)}
                            </div>
                          </div>
                          <div className='plannerProject__total'>
                            <div className='hdr'>Quoted Project Total</div>
                            <div className='value'>
                              {getQuotedTotal(project)}
                            </div>
                          </div>
                          <div className='plannerProject__billed'>
                            <div className='hdr'>Project Amount Billed</div>
                            <div className='value'>
                              {getAmountBilledPercent(project)}
                            </div>
                          </div>
                          <div className='plannerProject__billLeft'>
                            <div className='hdr'>Billable Amount Available</div>
                            <div className='value'>
                              {getBillableAmount(project)}
                            </div>
                          </div>
                          <div className='plannerProject__billBar'>
                            <div
                              className='billed'
                              style={{ flexBasis: getBilledPercent(project) }}
                            ></div>
                            <div
                              className='left'
                              style={{ flexBasis: getLeftPercent(project) }}
                            ></div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
