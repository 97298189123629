import React from 'react';
import { LoginStatus } from 'auth';

export function AppHeader() {
  return (
    <div className="appHeader">
      {/* <div className="appHeader__logo">
        <img src={`${env.publicBase}/assets/logos/logo.png`} alt="logo" />
      </div> */}
     <LoginStatus/>
    </div>
  );
}
