import React, { useState, useEffect } from 'react';
import env from 'env';
import { useGet } from 'components';
import { usePost } from 'components';
import { Loading, ErrorPopup } from 'components';
import { SiteLink } from 'components';
import utils from 'utils';
import { useRouter } from 'components';

import { EditLog } from '../task/editLog';
export function Dashboard(props) {

  const { query } = useRouter();
  const sprintId = query.id || '';
  // const [currentSprintId, SetcurrentSprintId] = useState(sprintId)
  //console.log(sprintId)

  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/dashboard/summary" + (sprintId ? "/" + sprintId : ""));
    // eslint-disable-next-line
  }, [sprintId]);

  // useEffect(() => {
  //   console.log(sprintId)
  //   SetcurrentSprintId(sprintId)
  // }, [sprintId])

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }
  const data = get.response;
  return (<Summary data={data} sprintId={sprintId}/>);
}
const n = utils.text.formatNumber;

export function Summary(props) {
  const data = props.data;
  //console.log(data)
  return (
    <div className="container">
      <h1 className="">{data.sprintTitle}</h1>
      <div style={{ float: 'right', marginTop: '-2rem' }}>
        <SiteLink style={{marginRight: '2rem', color:'chocolate'}} to={`/tasks/${props.sprintId}`}><b>TASKS</b></SiteLink>
        {data.lastSprintId && <SiteLink to={`/dashboard/${data.lastSprintId}`}> Previous week </SiteLink>}

      </div>
      <div className="staffSummary">
        {
          data.staffSummary.map(staff => (
            <StaffSummary staff={staff} key={staff.staffCode} />
          ))
        }
      </div>

    </div>
  );
}

function StaffSummary(props) {
  const staff = props.staff;
  let prWorked = staff.workingHoursSpentTotal === 0 ? 0 : staff.workingHoursSpentTotal / 50;//staff.workingHoursPlannedTotal;
  let prBilling = staff.billingHoursSpentTotal === 0 ? 0 : staff.billingHoursSpentTotal / 30;//staff.billingHoursPlannedTotal;

  prWorked = prWorked > 1 ? 1 : prWorked;
  prBilling = prBilling > 1 ? 1 : prBilling;

  const [showDetails, SetShowDetails] = useState(false)

  // const workType = [
  //   { value: 0, name: 'PM' },
  //   { value: 3, name: 'Admin' },
  //   { value: 1, name: 'Design/Content' },
  //   { value: 2, name: 'Tech' }
  // ]

  // function getWorkLogType(typeId) {
  //   var type = workType.find(x => x.value === typeId);
  //   return type ? type.name : '';
  // }

  return (
    <div className="staffSection">
      <h3 className="name">{staff.staffCode}</h3>
      <div className="row">
        <div className="col-md-3">
          Working Hours:
        </div>
        <div className="col-md-2">
          <b>{n(staff.workingHoursSpentTotal)}</b> hrs
           {/* / {n(staff.workingHoursPlannedTotal)} */}
        </div>
        <div className="col-md-7">
          <div className="progress">
            <div className="progressBar" style={{ width: `${prWorked * 100}%` }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          Billing Hours:
        </div>
        <div className="col-md-2">
          <b>{n(staff.billingHoursSpentTotal)}</b> hrs
           {/* / {n(staff.billingHoursPlannedTotal)} */}
        </div>
        <div className="col-md-7">
          <div className="progress">
            <div className="progressBar" style={{ width: `${prBilling * 100}%` }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          Logged so far:<br />

          {showDetails && <button className="showMore btn btn-outline-success btn-sm" onClick={() => { SetShowDetails(false) }}>Hide details</button>}
          {!showDetails && <button className="showMore btn btn-outline-success btn-sm" onClick={() => { SetShowDetails(true) }}>Show details</button>}
        </div>
        <div className="col-md-9">
          <div className="days">
            {staff.days.map(day => (
              <div className="day" key={day.dayOfWeek}>
                <div className="dayOfWeek">{day.dayOfWeek}</div>
                <div className="hours"><b>{n(day.logHours)}</b> hrs</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showDetails &&
        <div className="workLogsDashboard">
          {staff.logs && staff.logs.length > 0 ? <WorkLogs workLogs={staff.logs} isPrePurchased={false} /> : <div>No logs yet</div>}
{/* 
          {staff.logs.map(workLog => (
            <div className="workLog" key={workLog.workLogId}>
              <div className="date">{utils.date.formatYYYYMMDD(workLog.timeStart)}</div>
              <div className="project">{workLog.projectName || workLog.clientName}</div>
              <div className="workType">{getWorkLogType(workLog.workLogType)}</div>
              <div className="hrs">{n(workLog.spentHours)} hrs
                <div className="billingHrs">{n(workLog.billingHoursUsed || 0)} hrs</div>
              </div>
              <div className="desc">{workLog.workLogDetails}</div>
            </div>
          ))} */}
        </div>
      }
    </div>
  );
}



function WorkLogs({ workLogs, isPrePurchased }) {
  const [editingLog, setEditingLog] = React.useState(null)
  const total = workLogs.reduce((a, b) => +a + +b.spentHours, 0);

  const post = usePost();

  function removeLog(workLogId) {
    if (!window.confirm('Delete this??')) return;
    post.send(env.apiBase + "/api/worklog/removelog", { workLogId: workLogId });
  }

  if (post.done()) {
    window.location.reload();
  }

  // const workType = [
  //   { value: 0, name: 'PM' },
  //   { value: 3, name: 'Admin' },
  //   { value: 1, name: 'Design/Content' },
  //   { value: 2, name: 'Tech' },
  //   { value: 99, name: 'Purchased Hours' },
  // ]

  // function getWorkLogType(typeId) {
  //   var type = workType.find(x => x.value === typeId);
  //   return type ? type.name : '';
  // }

  return (<div>
    {!isPrePurchased && <div className="logSum">Total: {total} hrs</div>}
    {workLogs.map((workLog) => (
      <React.Fragment key={workLog.workLogId}>
        <div className={`workLog workLog-${workLog.workLogType}`} >
          <div className="date">{utils.date.formatDDMMYYYY(workLog.timeStart)}</div>
          {/* <div className="staff">{workLog.workLogType === 99 ?
            <span> {getWorkLogType(workLog.workLogType)}</span> : <span>{workLog.staffCode} / {getWorkLogType(workLog.workLogType)}</span>}</div> */}
          {/* <div className="workType">{getWorkLogType(workLog.workLogType)}</div> */}
          <div className="project">{workLog.projectName || workLog.clientName}</div>
          <div className="hrs">{n(workLog.spentHours)} hrs
          {workLog.workLogType !== 99 && <div className="billingHrs">{n(workLog.billingHoursUsed || 0)} hrs</div>}
          </div>
          <div className="desc">{workLog.workLogDetails}</div>
          {workLog.workLogType !== 99 && <div className="edit" onClick={(e) => setEditingLog(editingLog === workLog.workLogId ? null : workLog.workLogId)} >[edit]</div>}
          <div className="delete" onClick={(e) => removeLog(workLog.workLogId)} >[remove]</div>
        </div>

        {editingLog === workLog.workLogId && <EditLog workLogId={editingLog} isPrePurchased={isPrePurchased} />}
      </React.Fragment>
    ))}
  </div>)
}