import React, { useState, useEffect } from "react";

const ScrollButton = () => {
  const [isAtBottom, setIsAtBottom] = useState(true);

  const handleClick = () => {
    if (isAtBottom) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsAtBottom(!isAtBottom);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        setIsAtBottom(false);
      } else if (scrollTop === 0) {
        setIsAtBottom(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const buttonStyle = {
    position: "fixed",
    bottom: "2rem",
    right: "2rem",
    padding: "10px 15px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#5cb249",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    transition: "background-color 0.3s ease",
  };

  return (
    <button
      onClick={handleClick}
      style={buttonStyle}
      onMouseEnter={(e) => (e.target.style.backgroundColor = "#252525")}
      onMouseLeave={(e) => (e.target.style.backgroundColor = "#5cb249")}
    >
      {isAtBottom ? "Scroll to Bottom" : "Scroll to Top"}
    </button>
  );
};

export default ScrollButton;
