import React, {useEffect} from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet } from 'components';
import { SiteLink } from 'components';

import { Loading, ErrorPopup } from 'components';
//import FormPanel from 'form/components/FormPanel';

export function ClientList(props) {

  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/client/clientlist");
    // eslint-disable-next-line
  }, []);

 
  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  return (<ClientListTable data={data} />);

}

export function ClientListTable(props) {
  const { navigate } = useRouter();
  const data = props.data;
  console.log(data)
  return (
    <div className="container-wide">
      <h1 className="">Client List</h1>
      <div className="actions">
        <button className="btn btn-primary" type="button" onClick={() => {navigate('/client/new')}}>Add Client</button>
      </div>
      <table className="dt">
        <thead>
          <tr>
            <th>Client Code</th>
            <th>Client Name</th>
          </tr>
        </thead>
        <tbody>
          {data.map(client => (
            <tr key={client.clientCode}>
              <td>{client.clientCode}</td>
              <td><SiteLink to={`/client/edit/${client.clientCode}`}>{client.clientName} </SiteLink></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}