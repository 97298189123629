import { fb, validators } from 'form';

export function getNewWebsiteModel(data) {
  let clientList = data.clientList.map((p) => { return { name: p.clientName, value: p.clientCode } });
  clientList = [{ name: 'Select...', value: '' }, ...clientList];
  const model = fb.group({
    siteUrl: ['', [validators.Required()], { label: 'Site Url', type: 'text', helpText: 'Include https:// or http://, Use the main domain(www or non-www)' }],
    clientCode: [data.clientCode || '', [validators.Required()], { label: 'Client', type: 'select', options: clientList }]
  })
  return model;
}

export function getWebsiteEditModel(data) {

  const cmsVersionList = [
    { name: 'Select...', value: '' },
    { name: 'Cms7(PWA)', value: 'Cms7' },
    { name: 'Cms6(PWA-angular)', value: 'Cms6' },
    { name: 'Cms5', value: 'Cms5' },
    { name: 'Cms5 Beta', value: 'Cms5Beta' },
    { name: 'Cms4.1', value: 'Cms4.1' },
    { name: 'Cms4.0', value: 'Cms4.0' },
    { name: 'No Cms', value: 'NoCms' },
    { name: 'Other', value: 'Other' },
  ]

  const webServerList = [
    { name: 'Select...', value: '' },
    { name: 'Azure VM04', value: 'Azure VM04' },
    { name: 'Azure VM03', value: 'Azure VM03' },
    { name: 'Azure VM02', value: 'Azure VM02' },
    { name: 'Azure WebApp01', value: 'Azure WebApp01' },
    { name: 'ozHosting2', value: 'ozHosting2' },
    { name: 'Other', value: 'Other' },
  ]

  const webDbServerList = [
    { name: 'Select...', value: '' },
    { name: 'Azure VM04', value: 'Azure VM04' },
    { name: 'Azure VM03', value: 'Azure VM03' },
    { name: 'Azure VM02', value: 'Azure VM02' },
    { name: 'Azure SQL01', value: 'Azure SQL01' },
    { name: 'ozHostingDB(3)', value: 'ozHostingDB' },
    { name: 'No Db', value: 'NoDb' },
    { name: 'Other', value: 'Other' },
  ]

  const cmsDbServerList = [
    { name: 'Select...', value: '' },
    { name: 'Azure VM04', value: 'Azure VM04' },
    { name: 'Azure VM03', value: 'Azure VM03' },
    { name: 'Azure VM02', value: 'Azure VM02' },
    { name: 'Azure SQL01', value: 'Azure SQL01' },
    { name: 'ozHostingDB(3)', value: 'ozHostingDB' },
    { name: 'No Db', value: 'NoDb' },
    { name: 'Other', value: 'Other' },
  ]

  const sslTypeList = [
    { name: 'Select...', value: '' },
    { name: 'LetsEncrypt', value: 'LetsEncrypt' },
    { name: 'COMODO SSL', value: 'COMODO SSL' },
    { name: 'Sectigo SSL', value: 'Sectigo' },
    { name: 'Other', value: 'Other' },
  ]
  let clientList = data.clientList.map((p) => { return { name: p.clientName, value: p.clientCode } });
  clientList = [{ name: 'Select...', value: '' }, ...clientList];
  const model = fb.group({
    siteUrl: [data.siteUrl || '', [validators.Required()], { label: 'Site Url', type: 'text', helpText: 'Include https:// or http://, Use the main domain(www or non-www)' }],
    clientCode: [data.clientCode || '', [validators.Required()], { label: 'Client', type: 'select', options: clientList }],
    cmsVersion: [data.cmsVersion || '', [], { label: 'CMS Version', type: 'select', options: cmsVersionList }],

    webServer: [valOrOther(data.webServer, webServerList) || '', [], { label: 'Web Server', type: 'select', options: webServerList }],
    webServerOther: [other(data.webServer, webServerList) || '', [], { label: 'Web Server - Other', type: 'text' }],

    webServerInstance: [data.webServerInstance || '', [], { label: 'Web Root/Instance', type: 'text' }],

    webDbServer: [valOrOther(data.webDbServer, webDbServerList) || '', [], { label: 'Web DB Server', type: 'select', options: webDbServerList }],
    webDbServerOther: [other(data.webDbServer, webDbServerList) || '', [], { label: 'Web DB Server - Other', type: 'text' }],

    webDbName: [data.webDbName || '', [], { label: 'Web DB Name', type: 'text' }],

    cmsDbServer: [valOrOther(data.cmsDbServer, cmsDbServerList) || '', [], { label: 'CMS DB Server', type: 'select', options: cmsDbServerList }],
    cmsDbServerOther: [other(data.cmsDbServer, cmsDbServerList) || '', [], { label: 'CMS DB Server - Other', type: 'text' }],

    cmsDbName: [data.cmsDbName || '', [], { label: 'CMS DB Name', type: 'text' }],

    sslType: [valOrOther(data.sslType, sslTypeList) || '', [], { label: 'SSL Type', type: 'select', options: sslTypeList }],
    sslTypeOther: [other(data.sslType, sslTypeList) || '', [], { label: 'Other', type: 'text' }],

    sslExpiryDate: [data.sslExpiryDate || '', [], { label: 'SSL Expiry date', type: 'date' }],
    dateLive: [data.dateLive || '', [], { label: 'Live Date', type: 'date' }],
    notes: [data.notes || '', [], { label: 'Notes', type: 'textarea' }],
  });
  return model;
};

function valOrOther(value, list) {
  if (!value) return '';
  return list.some(x => x.value === value)? value : 'Other';
}

function other(value, list) {
  if (!value) return '';
  return list.some(x => x.value === value)? '' : value;
}