import React, { useEffect } from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet, usePost } from 'components';

import { useForm } from 'form';
import { Loading, ErrorPopup } from 'components';
import { Button, ErrorMessage } from 'components';
//import FormPanel from 'form/components/FormPanel';
import { getClientEditModel } from './clientEditModel';

export function ClientEdit(props) {
  const { query } = useRouter();
  const clientCode = query.id || '';
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/client/getclient/" + clientCode);
    // eslint-disable-next-line
  }, [clientCode]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  const model = getClientEditModel(data);
  return <ClientEditForm model={model} clientCode={clientCode} initModel={data} />;

}

export function ClientEditForm(props) {
  const { navigate } = useRouter();
  const model = props.model;
  const clientCode = props.clientCode;
  const initModel = props.initModel;

  console.log(model)
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(e, () => {
      
    const value = { ...initModel, ...form.getValue() };
    post.send(env.apiBase + "/api/client/saveclient", value);
    }, (errors) => { console.log(errors) })
  }

  function removeClient(e) {
    if (!window.confirm('Delete this??')) return;
    post.send(env.apiBase + "/api/client/removeclient", { clientCode: clientCode });
  }

  if (post.done()) {
    navigate(`/clients`)
  }

  
const render = (name) => form.renderControl(name);

  return (
    <div className="container">
      <h1 className="">{clientCode ? 'Edit' : 'New'} Client</h1>
      
      <div className="actions">
        <button className="btn btn-outline-primary" type="button" onClick={() => { navigate('/clients') }}>&lt; Return to List</button>
        {clientCode &&
          <Button onClick={() => { navigate('/website/new?clientCode=' + clientCode) }}btnStyle="outline-primary">Add Website</Button>}
      </div>

      <form>
        
        {/* <div className="row">
          <div className="col-md-12">{render('clientCode')}</div>
        </div> */}
        <div className="row">
          <div className="col-md-12">{render('clientName')}</div>
        </div>
        <div className="row">
          <div className="col-md-12">{render('clientStatus')}</div>
        </div>

        <div className="actions">
          <Button onClick={submitCallback} status={post.status}>Save</Button>
          {clientCode &&
            <Button onClick={() => { removeClient() }} status={post.status} btnStyle="outline-primary">Delete</Button>}
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>

      </form>

    </div>
  );
}