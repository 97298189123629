import React, { useEffect } from "react";
import env from "env";
import { useRouter } from "components";
import { useGet } from "components";
import { usePost } from "components";
import { SiteLink } from "components";

import { getTaskStatusName } from "./taskEnums";
import { Loading, ErrorPopup } from "components";
//import FormPanel from 'form/components/FormPanel';
import utils from "utils";
import { NoteEdit } from "./noteEdit";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaGripLines } from "react-icons/fa";

const n = utils.text.formatNumber;

export function TaskList(props) {
  const { query } = useRouter();
  const sprintId = query.id || "";

  const get = useGet();
  useEffect(() => {
    get.send(
      env.apiBase + "/api/task/tasklist" + (sprintId ? "/" + sprintId : "")
    );
    // eslint-disable-next-line
  }, [sprintId]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }
  const data = get.response;
  return <TaskListTable data={data} sprintId={sprintId} />;
}

export function TaskListTable(props) {
  const { navigate } = useRouter();
  const post = usePost();

  const [staffCode, setStaffCode] = React.useState(
    localStorage.getItem("staffCode") || ""
  );
  const [taskSortBy, setTaskSortBy] = React.useState(
    localStorage.getItem("taskSortBy") || ""
  );
  const [filteredTasks, setFilteredTasks] = React.useState(
    filterAndSort({ staffCode: staffCode, taskSortBy: taskSortBy })
  );

  const data = props.data;
  //console.log(data)
  //console.log(filteredTasks)

  function changeStaff(e) {
    localStorage.setItem("staffCode", e.target.value);
    setStaffCode(e.target.value);
    setFilteredTasks(
      filterAndSort({ staffCode: e.target.value, taskSortBy: taskSortBy })
    );
  }

  function changeSortBy(e) {
    localStorage.setItem("taskSortBy", e.target.value);
    setTaskSortBy(e.target.value);
    setFilteredTasks(
      filterAndSort({ staffCode: staffCode, taskSortBy: e.target.value })
    );
  }

  function filterAndSort({ staffCode, taskSortBy }) {
    const filtered = staffCode
      ? props.data.tasks.filter((x) => x.staffCode === staffCode)
      : props.data.tasks;
    function compare(a, b) {
      if (taskSortBy === "dueDateAsc")
        return utils.array.caseInsensitiveCompare(
          a.dateTo || "ZZZZZ",
          b.dateTo || "ZZZZZ"
        );
      if (taskSortBy === "dueDateDesc")
        return -utils.array.caseInsensitiveCompare(
          a.dateTo || "",
          b.dateTo || ""
        );
      return 0;
    }
    filtered.sort(compare);
    return filtered;
  }

  //let filteredTasks = staffCode ? props.data.tasks.filter(x => x.staffCode === staffCode) : props.data.tasks;
  const backlogs = staffCode
    ? props.data.backlogs.filter((x) => x.staffCode === staffCode)
    : props.data.backlogs;
  const noteList = staffCode
    ? props.data.noteList.filter((x) => x.staffCode === staffCode)
    : null;
  const notes = noteList && noteList.length > 0 ? noteList[0] : null;
  const h = (plan, spent) => n((spent || 0) - (plan || 0));
  const disp = (productCode) =>
    productCode &&
    productCode.toLowerCase().indexOf("clutter") < 0 &&
    productCode.toLowerCase().indexOf("r&d") < 0 &&
    productCode.toLowerCase() !== "sales";

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : null,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : null,
  });

  function onDragEnd(result) {
    //console.log(result)

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sorted = utils.array.moveItem(
      Array.from(filteredTasks || []),
      result.source.index,
      result.destination.index
    );
    setFilteredTasks(sorted);
    const ids = sorted.map((x) => x.taskId);
    const model = { ids: ids, staffCode: staffCode };
    post.send(env.apiBase + "/api/task/saveTaskOrders", model);
  }

  return (
    <div className="container-wide">
      <h1 className="">Task List - {data.sprintTitle}</h1>

      <div style={{ float: "right", marginTop: "-2rem" }}>
        <SiteLink
          style={{ marginRight: "2rem", color: "chocolate" }}
          to={`/dashboard/${props.sprintId}`}
        >
          <b>DASHBOARD</b>
        </SiteLink>
        {data.lastSprintId && (
          <SiteLink to={`/tasks/${data.lastSprintId}`}>
            Previous week
          </SiteLink>
        )}
      </div>

      <div className="actions">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            navigate("/task/new");
          }}
        >
          Add Task
        </button>

        <div className="filter" style={{ display: "inline-flex" }}>
          <select
            className="form-control"
            value={staffCode}
            onChange={changeStaff}
          >
            <option value="">All</option>
            {data.staffs.map((x) => (
              <option key={x.staffCode} value={x.staffCode}>
                {x.name}
              </option>
            ))}
          </select>
          <select
            className="form-control"
            value={taskSortBy}
            onChange={changeSortBy}
            style={{ marginLeft: 5 }}
          >
            <option value="">Sort By</option>
            <option value="dueDateAsc">Due Date - Asc</option>
            <option value="dueDateDesc">Due Date - Desc</option>
          </select>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <table
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className={`dt`}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Project/Client</th>
                  <th>Task</th>
                  <th>Status</th>
                  <th>Who</th>
                  {/* <th>
                    Remaining Hours{" "}
                    <div style={{ fontSize: "70%" }}>(only for projects)</div>
                  </th> */}
                  <th>Due Date</th>
                  <th>
                    Working Hours <div style={{ fontSize: "70%" }}>(Spent)</div>
                  </th>
                  <th>
                    Billing Hours <div style={{ fontSize: "70%" }}>(Used)</div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredTasks.map((task, index) => (
                  <Draggable
                    key={task.taskId}
                    draggableId={task.taskId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className={`taskStatus${task.taskStatus} staff${task.staffCode}`}
                      >
                        <td>
                          {staffCode && (
                            <div
                              className="handle"
                              {...provided.dragHandleProps}
                            >
                              <FaGripLines title="Change order" />
                            </div>
                          )}
                        </td>
                        <td style={{ fontSize: "80%" }}>
                          {task.projectName ? (
                            <SiteLink
                              style={{ color: "#3baf3f" }}
                              to={`/project/edit/${task.projectCode}`}
                            >
                              {task.projectName}{" "}
                            </SiteLink>
                          ) : (
                            <SiteLink to={`/client/edit/${task.clientCode}`}>
                              {task.clientName}{" "}
                            </SiteLink>
                          )}
                        </td>
                        <td>
                          <SiteLink to={`/task/view/${task.taskId}`}>
                            {task.taskTitle}{" "}
                            {task.urgent ? (
                              <span style={{ color: "red" }}>(Urgent!)</span>
                            ) : (
                              ""
                            )}{" "}
                          </SiteLink>
                        </td>
                        <td style={{ fontSize: "80%" }}>
                          {getTaskStatusName(task.taskStatus)}
                        </td>
                        <td style={{ fontSize: "80%" }}>{task.staffCode}</td>
                        {/* <td style={{ fontSize: "80%" }}>
                          {disp(task.projectCode) &&
                            `PM: ${h(
                              task.pmHoursSpent - task.pmHoursPlanned
                            )}, ADM: ${h(
                              task.adminHoursSpent - task.adminHoursPlanned
                            )}, D: ${h(
                              task.designHoursSpent - task.designHoursPlanned
                            )}, T: ${h(
                              task.techHoursSpent - task.techHoursPlanned
                            )}`}
                        </td> */}
                        <td>{utils.date.formatDDMMYYYY(task.dateTo)}</td>
                        <td>{n(task.workingHoursSpent || 0)}</td>
                        <td>{n(task.billingHoursUsed || 0)}</td>
                        <td style={{ fontSize: "80%" }}>
                          <SiteLink to={`/task/view/${task.taskId}`}>
                            LOG{" "}
                          </SiteLink>{" "}
                          |{" "}
                          <SiteLink to={`/task/edit/${task.taskId}`}>
                            edit
                          </SiteLink>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>

      <div className="row" style={{ marginTop: "1rem" }}>
        <div className="col-md-6">
          <h3>Backlogs</h3>
          <table className="dt">
            <thead>
              <tr>
                <th>Project/Client</th>
                <th>Task</th>
                <th>
                  Remaining Hours{" "}
                  <div style={{ fontSize: "70%" }}>(only for projects)</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {backlogs.map((task) => (
                <tr
                  key={task.taskId}
                  className={`taskStatus${task.taskStatus}`}
                >
                  <td style={{ fontSize: "80%" }}>
                    {task.projectName || task.clientName}
                  </td>
                  <td>
                    <SiteLink to={`/task/view/${task.taskId}`}>
                      {task.taskTitle}{" "}
                      {task.urgent ? (
                        <span style={{ color: "red" }}>(Urgent!)</span>
                      ) : (
                        ""
                      )}{" "}
                    </SiteLink>
                  </td>
                  <td style={{ fontSize: "80%" }}>
                    {disp(task.projectCode) &&
                      `PM: ${h(
                        task.pmHoursSpent - task.pmHoursPlanned
                      )}, ADM: ${h(
                        task.adminHoursSpent - task.adminHoursPlanned
                      )}, D: ${h(
                        task.designHoursSpent - task.designHoursPlanned
                      )}, T: ${h(task.techHoursSpent - task.techHoursPlanned)}`}
                  </td>
                  <td style={{ fontSize: "80%" }}>
                    <SiteLink to={`/task/view/${task.taskId}`}>LOG </SiteLink> |{" "}
                    <SiteLink to={`/task/edit/${task.taskId}`}>edit</SiteLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <h3>Notes</h3>
          <NoteEdit data={notes} me={staffCode === data.myStaffCode} />
        </div>
      </div>
    </div>
  );
}
