import { fb, validators } from "form";
import { TaskStatus } from "./taskEnums";
import utils from "utils";

const taskStatusEnums = [{ name: "Select...", value: "" }, ...TaskStatus];
const n = utils.text.formatNumber;

export function getTaskEditModel(data) {
  console.log(data);

  const getProjectName = (p) => {
    if (utils.site.isPrePurchased(p.projectType)) return p.projectName;
    else
      return (
        p.projectName +
        " => P " +
        n((p.pmHoursPlanned || 0) - (p.pmHoursSpent || 0)) +
        ", A " +
        n((p.adminHoursPlanned || 0) - (p.adminHoursSpent || 0)) +
        ", D " +
        n((p.designHoursPlanned || 0) - (p.designHoursSpent || 0)) +
        ", T " +
        n((p.techHoursPlanned || 0) - (p.techHoursSpent || 0)) +
        ""
      );
  };

  let projectList = data.projectList
    .map((p) => {
      return { name: getProjectName(p), value: p.projectCode };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  projectList = [{ name: "Select...", value: "" }, ...projectList];
  let clientList = data.clientList
    .map((p) => {
      return { name: p.clientName, value: p.clientCode };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  clientList = [{ name: "Select...", value: "" }, ...clientList];
  let staffList = data.staffList.map((p) => {
    return { name: p.name, value: p.staffCode };
  });
  staffList = [{ name: "Select...", value: "" }, ...staffList];
  let sprintList = data.sprintList.map((p) => {
    return { name: p.sprintName, value: p.sprintId };
  });
  sprintList = [
    { name: "Add to Backlog", value: "00000000-0000-0000-0000-000000000000" },
    ...sprintList,
  ];

  const staffCode =
    data.staffCode || localStorage.getItem("staffCodeLog") || "";
  // const model = [
  //     fb.control('taskStatus', 'Status', "select", data.taskStatus, [Required()], { options: taskStatusEnums }),
  //     fb.control('sprintId', 'Sprint/Backlog', "select", data.sprintId, [], { options: sprintList }),
  //     fb.control('urgent', 'Urgent?', "checkbox", data.urgent, []),
  //     fb.control('projectCode', 'Project', "select", data.projectCode || "", [], { options: projectList }),
  //     fb.control('clientCode', 'Or Client', "select", data.clientCode || "", [], { options: clientList }),
  //     fb.control('staffCode', 'Team Member', "select", staffCode, [Required()], { options: staffList }),
  //     fb.control('taskTitle', 'Title', "text", data.taskTitle, [Required()]),
  //     fb.control('workingHoursPlanned', 'Working Hours Planned (in hrs)', "number", data.workingHoursPlanned, []),
  //     fb.control('billingHoursPlanned', 'Billing Hours Planned (in hrs)', "number", data.billingHoursPlanned, []),
  //     fb.control('taskDetail', 'Task Detail', "textarea", data.taskDetail, []),
  // ];
  const model = fb.group({
    taskStatus: [
      data.taskStatus || "0",
      [validators.Required()],
      { label: "Status", type: "select", options: taskStatusEnums },
    ],
    sprintId: [
      data.sprintId || "",
      [validators.Required()],
      { label: "Sprint/Backlog", type: "select", options: sprintList },
    ],
    urgent: [data.urgent || false, [], { label: "Urgent?", type: "checkbox" }],
    projectCode: [
      data.projectCode || "",
      [],
      { label: "Project", type: "select", options: projectList },
    ],
    clientCode: [
      data.clientCode || "",
      [],
      { label: "Or Client", type: "select", options: clientList },
    ],
    staffCode: [
      staffCode || "",
      [validators.Required()],
      { label: "Team Member", type: "select", options: staffList },
    ],
    taskTitle: [
      data.taskTitle || "",
      [validators.Required()],
      { label: "Title", type: "text" },
    ],
    dateFrom: [data.dateFrom || "", [], { label: "Date", type: "date" }],
    dateTo: [data.dateTo || "", [], { label: "Due Date", type: "date" }],
    workingHoursPlanned: [
      data.workingHoursPlanned || "",
      [],
      { label: "Working Hours Planned (in hrs)", type: "number" },
    ],
    billingHoursPlanned: [
      data.billingHoursPlanned || "",
      [],
      { label: "Billing Hours Planned (in hrs)", type: "number" },
    ],
    taskDetail: [
      data.taskDetail || "",
      [],
      { label: "Task Detail", type: "textarea" },
    ],
  });
  return model;
}
