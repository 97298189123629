import variables from 'cssInJs/variables';
import { getIdToken } from 'lib/auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints
}

const iconColors = {
  primary: variables.primaryColor,
  secondary: variables.secondaryColor || '#000'
}

const firebaseSettings = {
  apiKey: "AIzaSyAtuNCYY5OdcjDEVWuQZFkqdEiwUecEuGM",
  authDomain: "jaba-pwa.firebaseapp.com",
  databaseURL: "https://jaba-pwa.firebaseio.com",
  projectId: "jaba-pwa",
  storageBucket: "jaba-pwa.appspot.com",
  messagingSenderId: "727092207149",
  appId: "1:727092207149:web:b047c9858655295ef562a4"
}

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  if (token) {
    options.headers = options.headers || {}
    options.headers['Authorization'] = `Bearer ${token}`
  }
}

export default {
  cssEmotion,
  iconColors,
  firebaseSettings,
  apiNetJwt
}