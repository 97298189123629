import React from 'react';
import env from 'env';
//import { useRouter } from 'components';
import { usePost } from 'components';

import { useForm } from 'form';
import { Button, ErrorMessage } from 'components';

import { fb, validators } from 'form';

export function NewPurchasedHours({projectCode}) {

  const model = getModel({});
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(e, () => {
      
    const value = {...form.getValue(), projectCode: projectCode};

    let spentHours = converToHour(value.spentHours)
    
    console.log(value.spentHours, spentHours)
    if (!spentHours){
      alert('Please check the Spent Hour.');
      return
    }
    value.spentHours = spentHours
    value.workLogType = 99;

    //localStorage.setItem('staffCodeLog', value.staffCode);
    post.send(env.apiBase + "/api/workLog/addLog", value);
    }, (errors) => { console.log(errors) })

  }

  function converToHour(val){
    if (typeof val === 'number') return val;
    if (val.indexOf('m') >= 0) {
      const parsed = parseFloat(val.replace('m', ''))
      if (parsed) return parsed / 60;
      else return null
    }
    else if (val.indexOf('min') >= 0) {
      const parsed = parseFloat(val.replace('min', ''))
      if (parsed) return parsed / 60;
      else return null
    }
    return parseFloat(val);
  }

  if (post.done()) {
    //navigate(`/task/view/${taskId}`)
    window.location.reload();
  }

  const render = (name) => form.renderControl(name);

  return (
    <form className="newLog">
        <div className="row">
          <div className="col-md-6">{render('timeStart')}</div>
          <div className="col-md-6">{render('spentHours')}</div>
        </div>
        <div className="row">
          <div className="col-md-12">{render('workLogDetails')}</div>
        </div>

        <div className="actions">
          <Button onClick={submitCallback} status={post.status}>Add Hours</Button>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>

      </form>
  );
}


function getModel(data) {
  // console.log(data);
    const model = fb.group({
      timeStart: [data.timeStart || '', [validators.Required()], { label: 'Date', type: 'date' }],
      spentHours: [data.spentHours || '', [validators.Required()], { label: `Hours purchased`, type: 'text' }],
      workLogDetails: [data.workLogDetails || '', [validators.Required()], { label: 'Details', type: 'textarea' }],
    });

    return model;
};
