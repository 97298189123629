export const ProjectStatus = [
  { name: "Active", value: 0 },
  { name: "Paused", value: 1 },
  { name: "Not Started", value: 2 },
  { name: "Completed", value: 99 },
];

export function getprojectStatusName(code) {
  const found = ProjectStatus.find((x) => x.value === code);
  return found ? found.name : "";
}
