import React from 'react';
import env from 'env';
import { usePost } from 'components';
import { useForm } from 'form';
import { Button, ErrorMessage } from 'components';
import { fb } from 'form';

export function NoteEdit({data, me}) {
  //console.log(data, me)
  const model = getNoteModel(data);

  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue() };
      post.send(env.apiBase + "/api/task/savenotes", value);
      }, (errors) => { console.log(errors) })

  }

  React.useEffect(() => {
    form.reset(model);
    // eslint-disable-next-line
  }, [data]);

  if (post.done()) {
    window.location.reload();
  }

  const render = (name) => form.renderControl(name);

  return (
    <div className="">
      <form>
        
        <div className="row">
          <div className="col-md-12">{render('notes')}</div>
        </div>

        {me && <div className="actions">
          <Button onClick={submitCallback} status={post.status}>Save Notes</Button>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
          {post.done() && <span style={{ color: 'chocolate', marginLeft: '1rem' }}>Successfully Saved!</span>}
        </div>}

      </form>
    </div>
  );
}



function getNoteModel(data) {
  //console.log(data);

  const model = fb.group({
      notes: [(data && data.notes) || '', [], { label: null, type: 'textarea' }],
  });
  return model;
};
