const config = {
  isProd: process.env.NODE_ENV === "production",
  apiBase: process.env.REACT_APP_API_ENDPOINT,
  publicBase: process.env.PUBLIC_URL,
  resourceBase: process.env.REACT_APP_RESOURCE_BASEURL,
  cmsEndpoint: process.env.REACT_APP_CMS_ENDPOINT,
  baseUrl: process.env.REACT_APP_BASEURL,
  ver: process.env.REACT_APP_VERSION,
  updateDate: process.env.REACT_APP_UPDATED_AT
}

console.log(process.env, config)
export default {
  ...config
}