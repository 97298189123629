import React, { useEffect } from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet, usePost } from 'components';
import { Button, ErrorMessage } from 'components';
import { Loading, ErrorPopup } from 'components';
import { getTaskStatusName } from './taskEnums';
import { NewLog } from './newLog';
import { EditLog } from './editLog';
import utils from 'utils';
import { PrePaidSummary } from '../project/projectEdit';

const n = utils.text.formatNumber;

export function TaskView(props) {
  const { query } = useRouter();
  const taskId = query.id || '';
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/task/gettask/" + taskId);
    // eslint-disable-next-line
  }, []);

  if (!taskId) return (<ErrorMessage errors={`No task given - Invalid page`} />);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  return (<TaskViewRender task={data} />);
}


export function TaskViewRender(props) {
  const { navigate } = useRouter();
  //const { Redirect } = useRouter();
  const task = props.task;
  console.log(task)

  const post = usePost();

  function markItDone(e) {
    post.send(env.apiBase + "/api/task/changetaskstatus", { taskId: task.taskId, taskStatus: 99 });
  }

  function recalculate(e) {
    post.send(env.apiBase + "/api/task/recalculate", { taskId: task.taskId });
  }

  if (post.done()) {
    //return <Redirect to="/tasks" />
    
    window.location.reload();
  }

  const isPrePurchased = task && utils.site.isPrePurchased(task.projectType);
  //console.log(task, task.projectType, isPrePurchased)

  return (
    <div className="container">
      <h1 className="">{task.taskTitle} {task.urgent ? (<span style={{ color: 'red' }}>(Urgent!)</span>) : ''}</h1>
      <div className="actions">
        <button className="btn btn-outline-primary" type="button" onClick={() => { navigate('/tasks') }}>&lt; Return to List</button>
        <button className="btn btn-primary" type="button" onClick={() => { navigate('/task/edit/' + task.taskId) }}>Edit Task</button>
        {task.taskStatus !== 99 &&
          <Button onClick={() => { markItDone() }} status={post.status} btnStyle="warning">Done!</Button>}
          <button className="btn btn-outline-primary" type="button" onClick={recalculate}>Re-calculate</button>
      </div>
      
      <ErrorMessage errors={post.errors} />

      <div className="dataView">

        <div className="row">
          <div className="col-md-3">Status: </div>
          <div className="col-md-9">{getTaskStatusName(task.taskStatus)}</div>
        </div>
        <div className="row">
          <div className="col-md-3">Project/Client: </div>
          <div className="col-md-9">{task.projectName || task.clientName}</div>
        </div>
        <div className="row">
          <div className="col-md-3">Staff: </div>
          <div className="col-md-9">{task.staffCode}</div>
        </div>
        <div className="row">
          <div className="col-md-3">Working Hours: <div style={{ fontSize: '70%' }}>(Spent)</div></div>
          <div className="col-md-9"><b>{n(task.workingHoursSpent || 0)}</b></div>
        </div>
        {!task.projectCode && 
        <div className="row">
          <div className="col-md-3">Billing Hours: <div style={{ fontSize: '70%' }}>(Planned)</div></div>
          <div className="col-md-9">{n(task.billingHoursPlanned || 0)}</div>
        </div>}
        <div className="row">
          <div className="col-md-3">Billing Hours: <div style={{ fontSize: '70%' }}>(Used)</div></div>
          <div className="col-md-9">{n(task.billingHoursUsed || 0)}</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div><b>Details</b></div>
            <pre>{task.taskDetail}</pre>
          </div>
        </div>
      </div>

      {isPrePurchased && <PrePaidSummary summary={task.prePaidSummary} />}

      <h3 style={{ marginTop: '2rem' }}>Work Logs</h3>
      {task.workLogList && task.workLogList.length > 0 ? <WorkLogs workLogs={task.workLogList} isPrePurchased={isPrePurchased} /> : <div>No logs yet</div>}
      <NewLog newLog={task.newLog} isPrePurchased={isPrePurchased}/>
    </div>
  );
}

function WorkLogs({ workLogs, isPrePurchased }) {
  const [editingLog, setEditingLog] = React.useState(null)
  const total = workLogs.reduce((a, b) => +a + +b.spentHours, 0);

  const post = usePost();

  function removeLog(workLogId) {
    if (!window.confirm('Delete this??')) return;
    post.send(env.apiBase + "/api/worklog/removelog", { workLogId: workLogId });
  }

  if (post.done()) {
    window.location.reload();
  }

  const workType = [
    { value: 0, name: 'PM' },
    { value: 3, name: 'Admin' },
    {value: 1, name: 'Design/Content' },
    {value: 2, name: 'Tech' },
    { value: 99, name: 'Purchased Hours' },
  ]

  function getWorkLogType(typeId){
    var type = workType.find(x => x.value === typeId);
    return type ? type.name : '';
  }

  return (<div>
    <div className="logSum">Total: {n(total)} hrs</div>
    {workLogs.map((workLog) => (
      <React.Fragment key={workLog.workLogId}>
      <div className={`workLog workLog-${workLog.workLogType}`}>
        <div className="date">{utils.date.formatDDMMYYYY(workLog.timeStart)}</div>
        <div className="staff">{workLog.workLogType === 99 ?
          <span> {getWorkLogType(workLog.workLogType)}</span> : <span>{workLog.staffCode} / {getWorkLogType(workLog.workLogType)}</span>}</div>
        {/* <div className="workType">{getWorkLogType(workLog.workLogType)}</div> */}
        <div className="hrs">{n(workLog.spentHours)} hrs
          {workLog.workLogType !== 99 && <div className="billingHrs">{n(workLog.billingHoursUsed || 0)} hrs</div>}
        </div>
        <div className="desc">{workLog.workLogDetails}</div>
        {workLog.workLogType !== 99 &&<div className="edit" onClick={(e) => setEditingLog(editingLog === workLog.workLogId ? null : workLog.workLogId)} >[edit]</div>}
        <div className="delete" onClick={(e) => removeLog(workLog.workLogId)} >[remove]</div>
      </div>
      {editingLog === workLog.workLogId && <EditLog workLogId={editingLog} isPrePurchased={isPrePurchased} />}
      </React.Fragment>
    ))}
  </div>)
}
