import { useState } from 'react';

const useStep = (steps_) => {
  const [steps, setSteps] = useState(steps_);

  const setActive = (id) => {
    setSteps(steps.map(step => { step.active = step.id === id; return step; }));
  };

  const setActiveByIndex = (index) => {
    setSteps(steps.map((step, inx) => { step.active = inx === index; return step; }));
  };

  const getActive = () => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].active) return steps[i];
    }
    return null;
  };

  const getActiveIndex = () => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].active) return i;
    }
    return -1;
  }

  const next = () => {
    const index = getActiveIndex();
    if (index < steps.length - 1)
      setActiveByIndex(index + 1);
  }

  const prev = () => {
    const index = getActiveIndex();
    if (index > 0)
      setActiveByIndex(index - 1);
  }

  return { steps: steps, setActive: setActive, getActive: getActive, getActiveIndex: getActiveIndex, next: next, prev: prev }
};

export { useStep };