import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { ProvideAuth } from "auth";

import LoginLayoutRoute from "layout/loginLayout";
import DashboardLayoutRoute from "layout/dashboardLayout";

import { Login } from "auth";

import { Dashboard } from "./site/dashboard/dashboard";
import { TaskEdit } from "./site/task/taskEdit";
import { TaskList } from "./site/task/taskList";
import { BacklogList } from "./site/task/backlogList";

import { TaskView } from "./site/task/taskView";
import { ClientEdit } from "./site/client/clientEdit";
import { ClientList } from "./site/client/clientList";
import { ProjectEdit } from "./site/project/projectEdit";
import { ProjectList } from "./site/project/projectList";
import { WebsiteList } from "./site/website/websiteList";
import { WebsiteEdit } from "./site/website/websiteEdit";
import { NewWebsite } from "./site/website/newWebsite";
import { Settings } from "./site/settings/settings";
import { Planner } from "./site/planner/planner";

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <DashboardLayoutRoute path="/dashboard/:id" component={Dashboard} />

          <DashboardLayoutRoute path="/planner" component={Planner} />
          <DashboardLayoutRoute path="/tasks/:id" component={TaskList} />

          <DashboardLayoutRoute path="/tasks" component={TaskList} />
          <DashboardLayoutRoute path="/task/edit/:id" component={TaskEdit} />
          <DashboardLayoutRoute path="/task/view/:id" component={TaskView} />
          <DashboardLayoutRoute path="/task/new" component={TaskEdit} />

          <DashboardLayoutRoute path="/clients" component={ClientList} />
          <DashboardLayoutRoute
            path="/client/edit/:id"
            component={ClientEdit}
          />
          <DashboardLayoutRoute path="/client/new" component={ClientEdit} />
          <DashboardLayoutRoute path="/projects" component={ProjectList} />
          <DashboardLayoutRoute
            path="/project/edit/:id"
            component={ProjectEdit}
          />
          <DashboardLayoutRoute path="/project/new" component={ProjectEdit} />
          <DashboardLayoutRoute path="/backlogs" component={BacklogList} />
          <DashboardLayoutRoute path="/websites" component={WebsiteList} />
          <DashboardLayoutRoute path="/website/new" component={NewWebsite} />
          <DashboardLayoutRoute
            path="/website/edit/:id"
            component={WebsiteEdit}
          />
          <DashboardLayoutRoute path="/settings" component={Settings} />

          <LoginLayoutRoute path="/login" component={Login} />
          <Route exact path="/admin/dashboard">
            <Redirect to="/" />
          </Route>
          <Route exact path="/admin/login">
            <Redirect to="/login" />
          </Route>

          <DashboardLayoutRoute path="/" component={Dashboard} />
          {/* <Route path="/">
            <Redirect to="/admin/login" />
          </Route> */}
        </Switch>
      </Router>
    </ProvideAuth>
  );
}
export default App;
