import React, { useEffect } from "react";
import env from "env";
import { useRouter } from "components";
import { useGet, usePost } from "components";

import { useForm } from "form";
import { Button, ErrorMessage } from "components";
import { Loading, ErrorPopup } from "components";
//import FormPanel from 'form/components/FormPanel';
import { getProjectEditModel } from "./projectEditModel";
import utils from "utils";
import { getprojectStatusName } from "../project/projectEnums";
import { NewPurchasedHours } from "./newPurchasedHours";
import { NewLog } from "../task/newLog";
import { EditLog } from "../task/editLog";
import { FaGripLines, FaPlusSquare, FaMinusSquare } from "react-icons/fa";
import { SiteLink } from "components";
import ScrollButton from "components/scrollButton/scrollButton";

const n = utils.text.formatNumber;
export function ProjectEdit(props) {
  const { query } = useRouter();
  const projectCode = query.id || "";
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/project/getproject/" + projectCode);
    // eslint-disable-next-line
  }, [projectCode]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;
  const model = getProjectEditModel(data);
  return (
    <ProjectEditForm model={model} projectCode={projectCode} initModel={data} />
  );
}

export function ProjectEditForm(props) {
  const [showAddPurchasedHours, SetShowAddPurchasedHours] =
    React.useState(false);
  const [showAddLog, SetShowAddLog] = React.useState(false);

  const { navigate, Redirect } = useRouter();
  const model = props.model;
  const projectCode = props.projectCode;

  const initModel = props.initModel;

  console.log(model);
  const form = useForm(model, {});
  const post = usePost();

  const getDate = (d) => utils.date.formatDate(d, "d MMM yyyy") || "-";
  const getDueDateClass = (d) => {
    const date = utils.date.toDate(d);
    const now = new Date();
    return date ? (date > now ? "green" : "red") : "";
  };

  const [displayingProjects, setDisplayingProjects] = React.useState([]);

  const displayingLogs = (projectCode, type) => {
    const found = displayingProjects.find(
      (x) => x.projectCode === projectCode && x.type === type
    );
    return !!found;
  };

  const showLog = (projectCode, type) => {
    setDisplayingProjects([...displayingProjects, ...[{ projectCode, type }]]);
  };

  const hideLog = (projectCode, type) => {
    setDisplayingProjects(
      displayingProjects.filter(
        (x) => !(x.projectCode === projectCode && x.type === type)
      )
    );
  };

  function submitCallback(e) {
    form.validateForm(
      e,
      () => {
        const value = { ...initModel, ...form.getValue() };
        post.send(env.apiBase + "/api/project/saveproject", value);
      },
      (errors) => {
        console.log(errors);
      }
    );
  }

  function removeProject(e) {
    if (!window.confirm("Delete this??")) return;
    post.send(env.apiBase + "/api/project/removeproject", {
      projectCode: projectCode,
    });
  }

  function recalculate(e) {
    post.send(env.apiBase + "/api/project/recalculate", {
      projectCode: projectCode,
    });
  }

  function getPercent(spent, planned) {
    const s = spent || 0;
    const p = planned || 0;
    if (p) {
      if (s) {
        if (s <= p) return (s / p) * 100 + "%";
        else {
          return s / p > 2 ? "100%" : (s / p - 1) * 100 + "%";
        }
      }
    } else {
      if (s) return "100%";
    }
    return "0.2%";
  }

  function getProgressStatus(spent, planned) {
    const s = spent || 0;
    const p = planned || 0;
    return (s && p && s > p) || (s && !p) ? "red" : "";
  }

  function getEstimatedWorkCompleted(project) {
    return project.estimatedWorkCompleted
      ? project.estimatedWorkCompleted + "%"
      : "-";
  }

  function getPrepaidHoursPurchased(project) {
    return project.prePaidSummary.totalPurchased
      ? project.prePaidSummary.totalPurchased + "h"
      : "-";
  }

  function getPrepaidStatus(project) {
    return project.prePaidSummary.balance < 0 ? "red" : "";
  }

  function getQuotedTotal(project) {
    return project.totalBillingAmount
      ? "$" + n(project.totalBillingAmount / 1000) + "k"
      : "-";
  }

  function getPrepaidRemaining(project) {
    return project.prePaidSummary.balance
      ? project.prePaidSummary.balance.toFixed(2) + "h"
      : "-";
  }

  function getAmountBilledPercent(project) {
    return project.amountBilledPercent
      ? project.amountBilledPercent + "%"
      : "-";
  }

  function getBillableAmount(project) {
    return project.totalBillingAmount
      ? "$" +
          n(
            (project.totalBillingAmount / 1000) *
              (1 -
                (project.amountBilledPercent
                  ? project.amountBilledPercent / 100
                  : 1))
          ) +
          "k"
      : "-";
  }

  function getBilledPercent(project) {
    return (project.amountBilledPercent || 0) + "%";
  }

  function getLeftPercent(project) {
    return 100 - (project.amountBilledPercent || 0) + "%";
  }

  const workType = [
    { value: 0, name: "PM" },
    { value: 3, name: "Admin" },
    { value: 1, name: "Design/Content" },
    { value: 2, name: "Tech" },
    { value: 99, name: "Purchased Hours" },
  ];

  function getWorkLogType(typeId) {
    var type = workType.find((x) => x.value === typeId);
    return type ? type.name : "";
  }

  function WorkLogItems({ workLog }) {
    return (
      <div className='workLog'>
        <div className='date'>
          {utils.date.formatDDMMYYYY(workLog.timeStart)}
        </div>
        <div className='staff'>
          {workLog.workLogType === 99 ? (
            <span> {getWorkLogType(workLog.workLogType)}</span>
          ) : (
            <span>
              {workLog.staffCode} / {getWorkLogType(workLog.workLogType)}
            </span>
          )}
        </div>
        {/* <div className="workType">{getWorkLogType(workLog.workLogType)}</div> */}
        <div className='hrs'>
          {n(workLog.spentHours)} hrs
          {workLog.workLogType !== 99 && (
            <div className='billingHrs'>
              {n(workLog.billingHoursUsed || 0)} hrs
            </div>
          )}
        </div>
        <div className='desc'>{workLog.workLogDetails}</div>
      </div>
    );
  }

  if (post.done()) {
    if (projectCode) {
      window.location.reload();
    } else {
      return <Redirect to={`/projects`} />;
    }
  }

  const data = form.getValue();
  const render = (name) => form.renderControl(name);

  const isPrePurchased = data && utils.site.isPrePurchased(data.projectType);

  return (
    <div className='container'>
      <div className='actions'>
        <button
          className='btn btn-outline-primary'
          type='button'
          onClick={() => {
            navigate("/projects");
          }}
        >
          &lt; Return to List
        </button>
        {projectCode && (
          <Button
            onClick={() => {
              recalculate();
            }}
            status={post.status}
            btnStyle='outline-primary'
          >
            Re-calculate
          </Button>
        )}
      </div>
      <h1 className=''>{projectCode ? "Edit" : "New"} Project</h1>
      <form>
        {/* <div className="row">
          <div className="col-md-12">{render('projectCode')}</div>
        </div> */}
        <div className='row'>
          <div className='col-md-12'>{render("projectName")}</div>
        </div>
        <div className='row'>
          <div className='col-md-4'>{render("projectType")}</div>
          <div className='col-md-4'>{render("clientCode")}</div>
          <div className='col-md-4'>{render("projectStatus")}</div>
        </div>

        <div className='row'>
          <div className='col-md-6'>{render("dateFrom")}</div>
          <div className='col-md-6'>{render("dateTo")}</div>
        </div>
        {isPrePurchased ? (
          <React.Fragment>
            <div className='row'>
              <div className='col-md-6'>{render("beginningBalance")}</div>
              <div className='col-md-6'>{render("plannerPrePurchaseType")}</div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='row'>
              <div className='col-md-6'>{render("estimatedWorkCompleted")}</div>
            </div>
            <div className='row'>
              <div className='col-md-6'>{render("totalBillingAmount")}</div>
              <div className='col-md-6'>{render("amountBilledPercent")}</div>
            </div>
            <div className='row'>
              <div className='col-md-6'>{render("pmHoursPlanned")}</div>
              <div className='col-md-6'>{render("adminHoursPlanned")}</div>
              <div className='col-md-6'>{render("designHoursPlanned")}</div>
              {/* <div className="col-md-6">{render('contentHoursPlanned')}</div> */}
              <div className='col-md-6'>{render("techHoursPlanned")}</div>
              {/* <div className="col-md-6">{render('otherHoursPlanned')}</div> */}
            </div>
          </React.Fragment>
        )}
        <div className='row'>
          <div className='col-md-12'>{render("notes")}</div>
        </div>
        <div className='actions'>
          <Button onClick={submitCallback} status={post.status}>
            Save
          </Button>
          {projectCode && (
            <Button
              onClick={() => {
                removeProject();
              }}
              status={post.status}
              btnStyle='outline-primary'
            >
              Delete
            </Button>
          )}

          <ErrorMessage
            errors={form.errors}
            summaryMessage='Please review the errors.'
          />
          <ErrorMessage errors={post.errors} />
        </div>
      </form>
      <div className='plannerProject'>
        <div className='plannerProject__handle'>
          {
            <div className='handle'>
              <FaGripLines title='Change order' />
            </div>
          }
        </div>
        <div className='plannerProject__details'>
          <SiteLink className='title' to={`/project/edit/${projectCode}`}>
            {initModel.projectName}{" "}
          </SiteLink>

          <div className='status'>
            STATUS:
            <span>{getprojectStatusName(initModel.projectStatus)}</span>
          </div>

          <div
            className={`dueDate dueDate--${getDueDateClass(initModel.dateTo)}`}
          >
            DUE:
            <span>{getDate(initModel.dateTo)}</span>
          </div>
        </div>

        {initModel.projectType === "Pre-purchased" ? (
          <>
            <div className='plannerProject__hours'>
              <div className='part'>
                <div className='title'>PROD-MAN</div>
                <div
                  className={`barWrapper barWrapper--${getProgressStatus(
                    initModel.pmHoursSpent + initModel.adminHoursSpent,
                    initModel.pmHoursPlanned + initModel.adminHoursPlanned
                  )}`}
                >
                  <div
                    className='bar'
                    style={{
                      width: getPercent(
                        initModel.pmHoursSpent + initModel.adminHoursSpent,
                        initModel.pmHoursPlanned + initModel.adminHoursPlanned
                      ),
                    }}
                  >
                    <div className='figure'>
                      {n(initModel.pmHoursSpent + initModel.adminHoursSpent)}/
                      {n(
                        initModel.pmHoursPlanned + initModel.adminHoursPlanned
                      )}
                    </div>
                  </div>
                </div>
                <div className='logs'>
                  {displayingLogs(projectCode, "pm") ? (
                    <FaMinusSquare onClick={() => hideLog(projectCode, "pm")} />
                  ) : (
                    <FaPlusSquare onClick={() => showLog(projectCode, "pm")} />
                  )}
                </div>
              </div>
              {displayingLogs(projectCode, "pm") && (
                <div className='partLogs'>
                  {initModel.workLogs
                    .filter((x) => x.workLogType === 0 || x.workLogType === 3)
                    .map((x) => (
                      <WorkLogItems key={x.workLogId} workLog={x} />
                    ))}
                </div>
              )}

              <div className='part'>
                <div className='title'>CREATIVE</div>
                <div
                  className={`barWrapper barWrapper--${getProgressStatus(
                    initModel.designHoursSpent,
                    initModel.designHoursPlanned
                  )}`}
                >
                  <div
                    className='bar'
                    style={{
                      width: getPercent(
                        initModel.designHoursSpent,
                        initModel.designHoursPlanned
                      ),
                    }}
                  >
                    <div className='figure'>
                      {n(initModel.designHoursSpent)}/
                      {n(initModel.designHoursPlanned)}
                    </div>
                  </div>
                </div>
                <div className='logs'>
                  {displayingLogs(projectCode, "design") ? (
                    <FaMinusSquare
                      onClick={() => hideLog(projectCode, "design")}
                    />
                  ) : (
                    <FaPlusSquare
                      onClick={() => showLog(projectCode, "design")}
                    />
                  )}
                </div>
              </div>
              {displayingLogs(projectCode, "design") && (
                <div className='partLogs'>
                  {initModel.workLogs
                    .filter((x) => x.workLogType === 1)
                    .map((x) => (
                      <WorkLogItems key={x.workLogId} workLog={x} />
                    ))}
                </div>
              )}
              <div className='part'>
                <div className='title'>TECHNICAL</div>
                <div
                  className={`barWrapper barWrapper--${getProgressStatus(
                    initModel.techHoursSpent,
                    initModel.techHoursPlanned
                  )}`}
                >
                  <div
                    className='bar'
                    style={{
                      width: getPercent(
                        initModel.techHoursSpent,
                        initModel.techHoursPlanned
                      ),
                    }}
                  >
                    <div className='figure'>
                      {n(initModel.techHoursSpent)}/
                      {n(initModel.techHoursPlanned)}
                    </div>
                  </div>
                </div>
                <div className='logs'>
                  {displayingLogs(projectCode, "tech") ? (
                    <FaMinusSquare
                      onClick={() => hideLog(projectCode, "tech")}
                    />
                  ) : (
                    <FaPlusSquare
                      onClick={() => showLog(projectCode, "tech")}
                    />
                  )}
                </div>
              </div>
              {displayingLogs(projectCode, "tech") && (
                <div className='partLogs'>
                  {initModel.workLogs
                    .filter((x) => x.workLogType === 2)
                    .map((x) => (
                      <WorkLogItems key={x.workLogId} workLog={x} />
                    ))}
                </div>
              )}
            </div>
            <div className='plannerProject__completed'>
              <div className='hdr'>Prepaid hours purchased</div>
              <div className='value'>{getPrepaidHoursPurchased(initModel)}</div>
            </div>
            <div className='plannerProject__total'>
              <div className='hdr'>Prepaid hours remaining</div>
              <div
                className={`value prepaid-remaining--${getPrepaidStatus(
                  initModel
                )}`}
              >
                {getPrepaidRemaining(initModel)}
              </div>
            </div>
            <div className='plannerProject__billed'>
              <div className='hdr'>Project Amount Billed</div>
              <div className='valuePrepaid'>
                <span>Prepaid</span>
              </div>
            </div>
            <div className='plannerProject__billLeft'>
              <div className='hdr'>Billable Amount Available</div>
              <div className='value'>{getBillableAmount(initModel)}</div>
            </div>
            <div className='plannerProject__billBar'>
              <div
                className='billed'
                style={{ flexBasis: getBilledPercent(initModel) }}
              ></div>
              <div
                className='left'
                style={{ flexBasis: getLeftPercent(initModel) }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <div className='plannerProject__hours'>
              <div className='part'>
                <div className='title'>PROD-MAN</div>
                <div
                  className={`barWrapper barWrapper--${getProgressStatus(
                    initModel.pmHoursSpent + initModel.adminHoursSpent,
                    initModel.pmHoursPlanned + initModel.adminHoursPlanned
                  )}`}
                >
                  <div
                    className='bar'
                    style={{
                      width: getPercent(
                        initModel.pmHoursSpent + initModel.adminHoursSpent,
                        initModel.pmHoursPlanned + initModel.adminHoursPlanned
                      ),
                    }}
                  >
                    <div className='figure'>
                      {n(initModel.pmHoursSpent + initModel.adminHoursSpent)}/
                      {n(
                        initModel.pmHoursPlanned + initModel.adminHoursPlanned
                      )}
                    </div>
                  </div>
                </div>
                <div className='logs'>
                  {displayingLogs(projectCode, "pm") ? (
                    <FaMinusSquare onClick={() => hideLog(projectCode, "pm")} />
                  ) : (
                    <FaPlusSquare onClick={() => showLog(projectCode, "pm")} />
                  )}
                </div>
              </div>
              {displayingLogs(projectCode, "pm") && (
                <div className='partLogs'>
                  {initModel.workLogs
                    .filter((x) => x.workLogType === 0 || x.workLogType === 3)
                    .map((x) => (
                      <WorkLogItems key={x.workLogId} workLog={x} />
                    ))}
                </div>
              )}

              <div className='part'>
                <div className='title'>CREATIVE</div>
                <div
                  className={`barWrapper barWrapper--${getProgressStatus(
                    initModel.designHoursSpent,
                    initModel.designHoursPlanned
                  )}`}
                >
                  <div
                    className='bar'
                    style={{
                      width: getPercent(
                        initModel.designHoursSpent,
                        initModel.designHoursPlanned
                      ),
                    }}
                  >
                    <div className='figure'>
                      {n(initModel.designHoursSpent)}/
                      {n(initModel.designHoursPlanned)}
                    </div>
                  </div>
                </div>
                <div className='logs'>
                  {displayingLogs(projectCode, "design") ? (
                    <FaMinusSquare
                      onClick={() => hideLog(projectCode, "design")}
                    />
                  ) : (
                    <FaPlusSquare
                      onClick={() => showLog(projectCode, "design")}
                    />
                  )}
                </div>
              </div>
              {displayingLogs(projectCode, "design") && (
                <div className='partLogs'>
                  {initModel.workLogs
                    .filter((x) => x.workLogType === 1)
                    .map((x) => (
                      <WorkLogItems key={x.workLogId} workLog={x} />
                    ))}
                </div>
              )}
              <div className='part'>
                <div className='title'>TECHNICAL</div>
                <div
                  className={`barWrapper barWrapper--${getProgressStatus(
                    initModel.techHoursSpent,
                    initModel.techHoursPlanned
                  )}`}
                >
                  <div
                    className='bar'
                    style={{
                      width: getPercent(
                        initModel.techHoursSpent,
                        initModel.techHoursPlanned
                      ),
                    }}
                  >
                    <div className='figure'>
                      {n(initModel.techHoursSpent)}/
                      {n(initModel.techHoursPlanned)}
                    </div>
                  </div>
                </div>
                <div className='logs'>
                  {displayingLogs(projectCode, "tech") ? (
                    <FaMinusSquare
                      onClick={() => hideLog(projectCode, "tech")}
                    />
                  ) : (
                    <FaPlusSquare
                      onClick={() => showLog(projectCode, "tech")}
                    />
                  )}
                </div>
              </div>
              {displayingLogs(projectCode, "tech") && (
                <div className='partLogs'>
                  {initModel.workLogs
                    .filter((x) => x.workLogType === 2)
                    .map((x) => (
                      <WorkLogItems key={x.workLogId} workLog={x} />
                    ))}
                </div>
              )}
            </div>
            <div className='plannerProject__completed'>
              <div className='hdr'>Estimated Work Completed</div>
              <div className='value'>
                {getEstimatedWorkCompleted(initModel)}
              </div>
            </div>
            <div className='plannerProject__total'>
              <div className='hdr'>Quoted Project Total</div>
              <div className='value'>{getQuotedTotal(initModel)}</div>
            </div>
            <div className='plannerProject__billed'>
              <div className='hdr'>Project Amount Billed</div>
              <div className='value'>{getAmountBilledPercent(initModel)}</div>
            </div>
            <div className='plannerProject__billLeft'>
              <div className='hdr'>Billable Amount Available</div>
              <div className='value'>{getBillableAmount(initModel)}</div>
            </div>
            <div className='plannerProject__billBar'>
              <div
                className='billed'
                style={{ flexBasis: getBilledPercent(initModel) }}
              ></div>
              <div
                className='left'
                style={{ flexBasis: getLeftPercent(initModel) }}
              ></div>
            </div>
          </>
        )}
      </div>
      {projectCode && (
        <React.Fragment>
          <h3 style={{ marginTop: "2rem" }}>Work Logs</h3>
          {initModel.workLogList && initModel.workLogList.length > 0 ? (
            <WorkLogs
              workLogs={initModel.workLogList}
              isPrePurchased={isPrePurchased}
            />
          ) : (
            <div>No logs yet</div>
          )}

          {}

          {isPrePurchased && (
            <PrePaidSummary summary={initModel.prePaidSummary} />
          )}

          <div style={{ marginTop: "1rem" }}>
            <button
              type='button'
              className='btn btn-primary'
              style={{ marginRight: "10px" }}
              onClick={() => SetShowAddLog(true)}
            >
              Add Log
            </button>

            {isPrePurchased && (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => SetShowAddPurchasedHours(true)}
              >
                Add Purchased Hours
              </button>
            )}
          </div>

          {showAddPurchasedHours && (
            <NewPurchasedHours projectCode={projectCode} />
          )}
          {showAddLog && (
            <NewLog newLog={initModel.newLog} isPrePurchased={isPrePurchased} />
          )}
        </React.Fragment>
      )}
      <ScrollButton />
    </div>
  );
}

function WorkLogs({ workLogs, isPrePurchased }) {
  const [editingLog, setEditingLog] = React.useState(null);
  const total = workLogs.reduce((a, b) => +a + +b.spentHours, 0);

  const post = usePost();

  function removeLog(workLogId) {
    if (!window.confirm("Delete this??")) return;
    post.send(env.apiBase + "/api/worklog/removelog", { workLogId: workLogId });
  }

  if (post.done()) {
    window.location.reload();
  }

  const workType = [
    { value: 0, name: "PM" },
    { value: 3, name: "Admin" },
    { value: 1, name: "Design/Content" },
    { value: 2, name: "Tech" },
    { value: 99, name: "Purchased Hours" },
  ];

  function getWorkLogType(typeId) {
    var type = workType.find((x) => x.value === typeId);
    return type ? type.name : "";
  }

  return (
    <div>
      {!isPrePurchased && <div className='logSum'>Total: {total} hrs</div>}
      {workLogs.map((workLog) => (
        <React.Fragment key={workLog.workLogId}>
          <div className={`workLog workLog-${workLog.workLogType}`}>
            <div className='date'>
              {utils.date.formatDDMMYYYY(workLog.timeStart)}
            </div>
            <div className='staff'>
              {workLog.workLogType === 99 ? (
                <span> {getWorkLogType(workLog.workLogType)}</span>
              ) : (
                <span>
                  {workLog.staffCode} / {getWorkLogType(workLog.workLogType)}
                </span>
              )}
            </div>
            {/* <div className="workType">{getWorkLogType(workLog.workLogType)}</div> */}
            <div className='hrs'>
              {n(workLog.spentHours)} hrs
              {workLog.workLogType !== 99 && (
                <div className='billingHrs'>
                  {n(workLog.billingHoursUsed || 0)} hrs
                </div>
              )}
            </div>
            <div className='desc'>{workLog.workLogDetails}</div>
            {workLog.workLogType !== 99 && (
              <div
                className='edit'
                onClick={(e) =>
                  setEditingLog(
                    editingLog === workLog.workLogId ? null : workLog.workLogId
                  )
                }
              >
                [edit]
              </div>
            )}
            <div
              className='delete'
              onClick={(e) => removeLog(workLog.workLogId)}
            >
              [remove]
            </div>
          </div>

          {editingLog === workLog.workLogId && (
            <EditLog workLogId={editingLog} isPrePurchased={isPrePurchased} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export function PrePaidSummary({ summary }) {
  return (
    <div
      style={{
        borderTop: "solid 3px #333",
        paddingTop: "0.5rem",
        margin: "1rem 0",
        display: "flex",
      }}
    >
      <div
        style={{
          backgroundColor: summary.balance > 0 ? "blue" : "red",
          color: "white",
          padding: "0.5rem 2rem",
          textAlign: "center",
          marginRight: "1rem",
        }}
      >
        <b>Remaining</b>
        <div>{utils.text.formatNumber(summary.balance)}</div>
      </div>
      <div
        style={{
          backgroundColor: "#bfe6bf",
          color: "black",
          padding: "0.5rem 2rem",
          textAlign: "center",
          marginRight: "1rem",
        }}
      >
        <b>Purchased</b>
        <div>{utils.text.formatNumber(summary.totalPurchased)}</div>
      </div>
      <div
        style={{
          backgroundColor: "#ffe5e5",
          color: "black",
          padding: "0.5rem 2rem",
          textAlign: "center",
          marginRight: "1rem",
        }}
      >
        <b>Used (billing hours)</b>
        <div>{utils.text.formatNumber(summary.totalUsed)}</div>
      </div>
      <div
        style={{
          backgroundColor: "#999",
          color: "white",
          padding: "0.5rem 2rem",
          textAlign: "center",
        }}
      >
        <b>Working hours</b>
        <div>{utils.text.formatNumber(summary.totalWorked)}</div>
      </div>
    </div>
  );
}
