import React, { useEffect } from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet } from 'components';
import { SiteLink } from 'components';
import utils from 'utils';

import { Loading, ErrorPopup } from 'components';
//import FormPanel from 'form/components/FormPanel';

export function WebsiteList(props) {

  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/website/websitelist");
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  return (<ListTable data={data} />);
}

export function ListTable(props) {
  const { navigate } = useRouter();
  const data = props.data;

  return (
    <div className="container-wide">
      <h1 className="">Website List</h1>
      <div className="actions">
        <button className="btn btn-primary" type="button" onClick={() => { navigate('/website/new') }}>Add Website</button>

      </div>

      <table className="dt">
        <thead>
          <tr>
            {/* <th>Project Code</th> */}
            <th>Website</th>
            <th>Cms</th>
            <th>Web Hosting</th>
            <th>Went Live</th>
            <th>Date Created</th>
          </tr>
        </thead>
        <tbody>
          {data.map(website => (
            <tr key={website.websiteId}>
              <td><SiteLink to={`/website/edit/${website.websiteId}`}>{website.siteUrl} </SiteLink></td>
              <td>{website.cmsVersion}</td>
              <td>{website.webServer}</td>
              <td style={{ fontSize: '80%' }}>{utils.date.formatDDMMYYYY(website.dateLive)}</td>
              <td style={{ fontSize: '80%' }}>{utils.date.formatDDMMYYYY(website.dateCreated)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
