import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Link } from 'react-router-dom';
import { useAuth } from '../../../../auth';
import { useRouter } from '../../../../components';

export function LoginStatus(props) {
  const auth = useAuth();
  const router = useRouter();

  function signout(e) {
    e.preventDefault();
    e.stopPropagation();

    auth.signout().then(user => {
      router.push('/admin/login')
    }).catch(error => {
      console.log(error)
    })
  }

  const email = (auth.user && auth.user.email) || '';

  if (router.path === '/admin/login') return null;
  if (!auth.isResolved) return null;
  return (
    <div css={{
      position: 'absolute',
      top: '1rem',
      right: '0.5rem',
      'a': {
        color: 'white'
      }
    }} >
      {auth.user ? (
          <React.Fragment>
            <Link to="/admin/dashboard" style={{ fontSize: '80%', marginRight: '0.5rem'}}>{email}</Link>
            <button className="btn btn-primary btn-sm" onClick={(e) => signout(e)}>Logout</button>
          </React.Fragment>
        ) : (
          <Link className="btn btn-primary btn-sm" to="/admin/login">Login</Link>
        )}
    </div>)
}