import React from 'react';
import { Route } from 'react-router-dom';
import { AppHeader } from 'layout/appHeader';

const LoginLayout = ({ children }) => (
  <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer">
    <AppHeader />
    <div className="appMain">
      <div className="appMain__inner">
        {children}
      </div>
    </div>
  </div>
);

const LoginLayoutRoute = ({ component: RenderComponent, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <LoginLayout>
        <RenderComponent {...matchProps} />
      </LoginLayout>
    )} />
  )
};

export default LoginLayoutRoute;  