import React, { useEffect } from "react";
import env from "env";
import { useGet } from "components";

import { Loading, ErrorPopup } from "components";

import { NewLog } from "./newLog";

export function EditLog({ workLogId, isPrePurchased }) {
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/worklog/getlog/" + workLogId);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;
  return (
    <NewLog newLog={data} isPrePurchased={isPrePurchased} editing={true} />
  );
}
