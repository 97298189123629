import React, { useEffect } from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet, usePost } from 'components';

import { useForm } from 'form';
import { Button, ErrorMessage } from 'components';
import { Loading, ErrorPopup } from 'components';
//import FormPanel from 'form/components/FormPanel';
import { getNewWebsiteModel } from './websiteEditModel';

export function NewWebsite(props) {
  const { query } = useRouter();
  const clientCode = query.clientCode || '';
  
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/website/getwebsite/");
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  data.clientCode = clientCode;
  const model = getNewWebsiteModel(data);
  return (<WebsiteEditForm model={model} initModel={data} />);  
}

export function WebsiteEditForm(props) {
  const { Redirect } = useRouter();
  const model = props.model;
  const initModel = props.initModel;

  console.log(model)
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(e, () => {
      const value = { ...initModel, ...form.getValue() };
      post.send(env.apiBase + "/api/website/newwebsite", value);
    }, (errors) => { console.log(errors) })
  }

  const data = post.response;
  console.log(data)
  if (post.done()) {
    return <Redirect to={`/website/edit/${data.results.websiteId}`} />
  }

  const render = (name) => form.renderControl(name);

  return (
    <div className="container">

      <h1 className="">New Website</h1>
      <form>
        <div className="row">
          <div className="col-md-12">{render('siteUrl')}</div>
        </div>
        <div className="row">
          <div className="col-md-4">{render('clientCode')}</div>
        </div>

        <div className="actions">
          <Button onClick={submitCallback} status={post.status}>Save</Button>

          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>

      </form>
    </div>
  );
}
