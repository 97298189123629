import React, { useEffect } from 'react';
import { fb, validators } from 'form';

import env from 'env';
import { useRouter } from 'components';
import { useGet, usePost } from 'components';

import { useForm } from 'form';
import { Loading, ErrorPopup } from 'components';
import { Button, ErrorMessage } from 'components';
import utils from 'utils';

const dt = utils.date.formatYYYYMMDD;

export function Settings(props) {
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/admin/getCurrentSprint");
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  const model = getEditModel(data);
  return <NewSprint model={model} currentSprint={data} />;

}

function getEditModel(data) {
  console.log('------', data)
  const sprintName = data.sprintName ? data.sprintName + '**' : '';
  const from = data.fromDate ? utils.date.addDays(data.fromDate, 7) : '';
  const to = data.toDate ? utils.date.addDays(data.toDate, 7) : '';
  const model = fb.group({
    sprintName: [sprintName, [validators.Required()], { label: 'Sprint Name', type: 'text', helpText: '!! Please change the sprint name.' }],
    fromDate: [from, [validators.Required()], { label: 'From', type: 'date' }],
    toDate: [to || '', [validators.Required()], { label: 'To', type: 'date' }],
  });
  return model;
};

export function NewSprint({ model, currentSprint }) {
  const { Redirect } = useRouter();

  console.log(model)
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(e, () => {

      const value = { ...form.getValue() };
      value.fromDate = utils.date.formatYYYYMMDD(value.fromDate);
      value.toDate = utils.date.formatYYYYMMDD(value.toDate);
      //console.log(value);
      post.send(env.apiBase + "/api/admin/createSprint", value);
    }, (errors) => { console.log(errors) })
  }

  if (post.done()) {
    return <Redirect to="/" />
  }

  const render = (name) => form.renderControl(name);

  return (
    <div className="container">
      <h1 className="">Create Sprint</h1>

      <form>

        <div style={{ color: 'chocolate', marginBottom: '1em' }}>Current: {currentSprint.sprintName} ({dt(currentSprint.fromDate)} - {dt(currentSprint.toDate)})</div>
        <div className="row">
          <div className="col-md-6">{render('sprintName')}</div>
        </div>
        <div className="row">
          <div className="col-md-6">{render('fromDate')}</div>
          <div className="col-md-6">{render('toDate')}</div>
        </div>

        <div className="actions">
          <Button onClick={submitCallback} status={post.status}>Create</Button>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>

      </form>

    </div>
  );
}