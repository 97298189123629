import React, { useEffect } from 'react';
import env from 'env';
import { useRouter } from 'components';
import { useGet, usePost } from 'components';

import { useForm } from 'form';
import { Button, ErrorMessage } from 'components';
import { Loading, ErrorPopup } from 'components';
//import FormPanel from 'form/components/FormPanel';
import { getWebsiteEditModel } from './websiteEditModel';

export function WebsiteEdit(props) {
  const { query } = useRouter();
  const websiteId = query.id || '';
  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + "/api/website/getwebsite/" + websiteId);
    // eslint-disable-next-line
  }, [websiteId]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;
  const model = getWebsiteEditModel(data);
  return (<WebsiteEditForm model={model} websiteId={websiteId} initModel={data} />);
}

export function WebsiteEditForm(props) {
  const { navigate, Redirect } = useRouter();
  const model = props.model;
  const websiteId = props.websiteId;
  const initModel = props.initModel;

  console.log(model)
  const form = useForm(model, {});
  const post = usePost();

  function submitCallback(e) {
    form.validateForm(e, () => {
      const value = { ...initModel, ...form.getValue() };
      value.webServer = setOther(value.webServer, value.webServerOther)
      value.webDbServer = setOther(value.webDbServer, value.webDbServerOther)
      value.cmsDbServer = setOther(value.cmsDbServer, value.cmsDbServerOther)
      value.sslType = setOther(value.sslType, value.sslTypeOther)
      post.send(env.apiBase + "/api/website/savewebsite", value);
    }, (errors) => { console.log(errors) })
  }

  function setOther(value, otherVal){
    return value === 'Other'? otherVal : value;
  }

  function removeWebsite(e) {
    if (!window.confirm('Delete this??')) return;
    post.send(env.apiBase + "/api/website/removewebsite", { websiteId: websiteId });
  }

  if (post.done()) {
    return <Redirect to={`/websites`} />
  }

  const data = form.getValue();
  const render = (name) => form.renderControl(name);


  return (
    <div className="container">

      <div className="actions">
        <button className="btn btn-outline-primary" type="button" onClick={() => { navigate('/websites') }}>&lt; Return to List</button>
      </div>
      <h1 className="">Edit Website </h1>
      <form>
        <div className="row">
          <div className="col-md-12">{render('siteUrl')}</div>
        </div>
        <div className="row">
          <div className="col-md-4">{render('clientCode')}</div>
          <div className="col-md-4">{render('cmsVersion')}</div>
          <div className="col-md-4">{render('dateLive')}</div>
        </div>

        <div className="row" style={{background: '#e8e8e8'}}>
          <div className="col-md-4">
            {render('webServer')}
            {data.webServer === 'Other' && <div>{render('webServerOther')}</div>}
            {render('webServerInstance')}
          </div>
          
          <div className="col-md-4">
          {render('webDbServer')}
            {data.webDbServer === 'Other' && <div>{render('webDbServerOther')}</div>}
            {render('webDbName')}

          </div>
          <div className="col-md-4">
          {render('cmsDbServer')}
            {data.cmsDbServer === 'Other' && <div>{render('cmsDbServerOther')}</div>}
            {render('cmsDbName')}

          </div>
        </div>
        <div className="row">
          <div className="col-md-6">{render('sslType')}
          {data.sslType === 'Other' && <div>{render('sslTypeOther')}</div>}
          </div>
          <div className="col-md-6">{render('sslExpiryDate')}</div>
        </div>
        <div className="row">
          <div className="col-md-12">{render('notes')}</div>
        </div>
        <div className="actions">
          <Button onClick={submitCallback} status={post.status}>Save</Button>
          {websiteId &&
            <Button onClick={() => { removeWebsite() }} status={post.status} btnStyle="outline-primary">Delete</Button>}

          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>

      </form>
    </div>
  );
}
