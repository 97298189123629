import { fb, validators, formModelHelper } from "form";
import { Required } from "lib/form/validators/required";

import { ProjectStatus } from "./projectEnums";

const projectStatusEnums = [{ name: "Select...", value: "" }, ...ProjectStatus];

export function getProjectEditModel(data) {
  let percentages = [{ name: "Not set", value: null }];
  for (let i = 0; i <= 100; i += 5)
    percentages.push({ name: `${i}%`, value: i });

  let clientList = data.clientList.map((p) => {
    return { name: p.clientName, value: p.clientCode };
  });
  clientList = [{ name: "Select...", value: "" }, ...clientList];
  
  const projectTypes = [
    { name: "Select...", value: "" },
    { name: "Normal", value: "Normal" },
    { name: "Pre-purchased", value: "Pre-purchased" },
  ];

  const plannerPrePurchaseTypes = [
    { name: "Select...", value: "" },
    { name: "Yes", value: "yes" },
    { name: "No", value: "no" },
  ];

  // const model = [
  //     fb.control('projectStatus', 'Status', "select", data.projectStatus, [Required()], { options: projectStatusEnums }),
  //     fb.control('projectName', 'Project Name', "text", data.projectName, [Required()]),
  //     fb.control('clientCode', 'Client', "select", data.clientCode || "", [Required()], { options: clientList }),
  //     fb.control('dateFrom', 'Date', "date", data.dateFrom, []),
  //     fb.control('dateTo', 'Date', "date", data.dateTo, []),
  //     fb.control('pmHoursPlanned', 'Admin(PM) Hours Planned (in hrs)', "number", data.pmHoursPlanned, [Required()]),
  //     fb.control('designHoursPlanned', 'Design Hours Planned (in hrs)', "number", data.designHoursPlanned, [Required()]),
  //     //fb.control('contentHoursPlanned', 'Content Entering Hours Planned (in hrs)', "number", data.contentHoursPlanned, [Required()]),
  //     fb.control('techHoursPlanned', 'Tech Hours Planned (in hrs)', "number", data.techHoursPlanned, [Required()]),
  //     //fb.control('otherHoursPlanned', 'Other Hours Planned (in hrs)', "number", data.otherHoursPlanned, [Required()]),
  //     fb.control('notes', 'Project Detail', "textarea", data.notes, []),
  // ];
  const model = fb.group({
    projectStatus: [
      data.projectStatus,
      [validators.Required()],
      { label: "Status", type: "select", options: projectStatusEnums },
    ],
    projectName: [
      data.projectName || "",
      [validators.Required()],
      { label: "Project Name", type: "text" },
    ],
    clientCode: [
      data.clientCode || "",
      [validators.Required()],
      { label: "Client", type: "select", options: clientList },
    ],
    plannerPrePurchaseType: [
      data.plannerPrePurchaseType || "",
      [],
      { label: "Planner for Pre Purchase", type: "select", options: plannerPrePurchaseTypes },
    ],
    projectType: [
      data.projectType || "",
      [],
      {
        label: "Project Type",
        type: "select",
        options: projectTypes,

        onBeforeUpdateValue: (control, formModel) => {
          const beginningBalance = formModelHelper.findControl(
            "beginningBalance",
            formModel
          );
          const pmHoursPlanned = formModelHelper.findControl(
            "pmHoursPlanned",
            formModel
          );
          const adminHoursPlanned = formModelHelper.findControl(
            "adminHoursPlanned",
            formModel
          );
          const designHoursPlanned = formModelHelper.findControl(
            "designHoursPlanned",
            formModel
          );
          const techHoursPlanned = formModelHelper.findControl(
            "techHoursPlanned",
            formModel
          );
          if (control.value === "Pre-purchased") {
            beginningBalance.validators.push(Required());
            pmHoursPlanned.validators = pmHoursPlanned.validators.filter(
              (x) => x.type !== "required"
            );
            adminHoursPlanned.validators = adminHoursPlanned.validators.filter(
              (x) => x.type !== "required"
            );
            designHoursPlanned.validators =
              designHoursPlanned.validators.filter(
                (x) => x.type !== "required"
              );
            techHoursPlanned.validators = techHoursPlanned.validators.filter(
              (x) => x.type !== "required"
            );
          } else {
            pmHoursPlanned.validators.push(Required());
            adminHoursPlanned.validators.push(Required());
            designHoursPlanned.validators.push(Required());
            techHoursPlanned.validators.push(Required());
            beginningBalance.validators = beginningBalance.validators.filter(
              (x) => x.type !== "required"
            );
          }
        },
      },
    ],
    dateFrom: [data.dateFrom || "", [], { label: "Date From", type: "date" }],
    dateTo: [data.dateTo || "", [], { label: "To", type: "date" }],
    totalBillingAmount: [
      data.totalBillingAmount,
      [],
      { label: "Quoted Total Amount ($)", type: "number" },
    ],
    beginningBalance: [
      data.beginningBalance,
      [],
      { label: "Beginning Balance (in hrs)", type: "number" },
    ],
    pmHoursPlanned: [
      data.pmHoursPlanned,
      [],
      { label: "PM Hours Planned (in hrs)", type: "number" },
    ],
    adminHoursPlanned: [
      data.adminHoursPlanned,
      [],
      { label: "Testing go live (in hrs)", type: "number" },
    ],
    designHoursPlanned: [
      data.designHoursPlanned,
      [],
      { label: "Design Hours Planned (in hrs)", type: "number" },
    ],
    techHoursPlanned: [
      data.techHoursPlanned,
      [],
      { label: "Tech Hours Planned (in hrs)", type: "number" },
    ],

    estimatedWorkCompleted: [
      data.estimatedWorkCompleted,
      [],
      {
        label: "Estimated Work Completed",
        type: "select",
        options: percentages,
      },
    ],
    amountBilledPercent: [
      data.amountBilledPercent,
      [],
      {
        label: "Project Amount Billed (%)",
        type: "select",
        options: percentages,
      },
    ],
    notes: [
      data.notes || "",
      [],
      { label: "Project Detail", type: "textarea" },
    ],
  });
  return model;
}
